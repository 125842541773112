import React, { useEffect, useState } from "react";
// Components
// import OverlayModal from "../OverlayModal";
// Assets
import { iconStarActive, iconStarInactive } from "../../atoms/Icons";
// import { StateView } from "../../../utils/StateView";
import api from "../../../utils/api";
import { toast } from "react-toastify";

const Star = ({ input, id, orgId, updateStarLocally }) => {
  const [star, setStar] = useState(false);
  // const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setStar(input);
  }, [input]);

  const _handleStar = () => {
    // console.log("star:", star);
    if (input) {
      api
        .post(
          `organisation/${orgId}/video/unstar/${id}`,
          {},
          {
            headers: { disableLoading: true },
          }
        )
        .then((response) => {
          const { data } = response;
          // console.log("response unstar:", response);
          toast.success("Removed from Starred videos");
          setStar(false);
          updateStarLocally(false, id);
        })
        .catch((error) => {
          const { data } = error;
          // console.log("error:", error);
          toast.error(data.message);
        });
    } else {
      api
        .post(
          `organisation/${orgId}/video/star/${id}`,
          {},
          {
            headers: { disableLoading: true },
          }
        )
        .then((response) => {
          const { data } = response;
          // console.log("response star:", response);
          toast.success("Starred successfully");
          setStar(true);
          updateStarLocally(true, id);
        })
        .catch((error) => {
          const { data } = error;
          console.log("error:", error);
          toast.error(data.message);
        });
    }
  };

  return (
    <>
      {/* <StateView values={input} title="Starred Input" /> */}
      <div
        className="w-12 h-12 relative hover:cursor-pointer"
        onClick={() => _handleStar()}
      >
        <img src={iconStarInactive} alt="" className="w-full h-full" />
        {star && (
          <img
            src={iconStarActive}
            alt=""
            className="w-full h-full absolute top-0 left-0"
          />
        )}
      </div>
    </>
  );
};

export default Star;
