import React from "react";

const Button = (props) => {
  const { type, twWidth, twClasses, disabled, ...filteredProps } = props;

  let variantClass;

  switch (type) {
    case "primary":
      variantClass = `${
        props.active
          ? "bg-pictonblue-active border-2 border-pictonblue-active"
          : "bg-pictonblue border-2 border-pictonblue"
      } hover:bg-pictonblue-active`;
      break;
    case "secondary":
      variantClass = "bg-mirage border-2 border-mirage hover:bg-mirage-active";
      break;
    case "tertiary":
      variantClass =
        "bg-white border-2 border-black text-black hover:opacity-50";
      break;
    default:
      variantClass = "bg-pictonblue hover:bg-pictonblue-active";
  }

  return (
    <button
      className={`${variantClass} transform transition-colors duration-150 block ${
        twWidth ? twWidth : "w-full"
      } ${
        disabled && !props.active && "opacity-50"
      } rounded-sm text-white max-h-[50px] p-3 font-bold ${
        twClasses && twClasses
      }`}
      disabled={disabled && disabled}
      {...filteredProps}
    >
      {props.children}
    </button>
  );
};

export default Button;
