import React, { useEffect, useState } from "react";
// Components
import { iconPriorityRed, iconPriority_Navy } from "../../atoms/Icons";
// Utilities
// import { StateView } from "../../../utils/StateView";

const Priority = ({ input }) => {
  const [badge, setBadge] = useState("");

  useEffect(() => {
    setBadge(input);
  }, [input]);

  return (
    badge &&
    badge.length !== 0 && (
      <>
        {/* <StateView values={input} title="Starred Input" /> */}
        {input === "DEPRIORITIZED" ? (
          <div className="w-12 h-12 relative">
            <img type="Priority resolved" src={iconPriority_Navy} alt="" className="w-full h-full" />
          </div>
        ) : (
          <div className="w-12 h-12 relative">
            <img title="Priority" src={iconPriorityRed} alt="" className="w-full h-full" />
          </div>
        )}
      </>
    )
  );
};

export default Priority;
