import React from "react";
import _ from "lodash";
import { FieldWrapper } from ".";

const ToggleSwitch = React.forwardRef((props, ref) => {
  // const inputClasses = `block w-full px-4 py-5 rounded-md max-w-[450px] border border-gray-200 focus-visible:outline-pictonblue `;

  return (
    <FieldWrapper
      label={props.label}
      error={_.isString(props.error) && props.error}
      alignment={props.alignment}
      customToggleSwitch={props.customToggleSwitch}
    >
      <label className="switch">
        <input
          type="checkbox"
          name={props.name}
          // value={props.value}
          checked={props.checked}
          onChange={props.onChange}
        ></input>
        <span className="slider round"></span>
      </label>
    </FieldWrapper>
  );
});

export default ToggleSwitch;
