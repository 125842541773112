import React from "react";

const VideoPageLayout = (props) => {
  return (
    <div className="flex gap-12 items-start w-full">
      <div className={`w-3/4 ${props.leftClasses}`}>{props.leftContent}</div>
      <div className={`w-1/4 ${props.rightClasses}`}>{props.rightContent}</div>
    </div>
  );
};

export default VideoPageLayout;
