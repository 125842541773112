import React from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
/**
 * Data Table Styling Tip
 * 'customStyles' is recommended compared to overriding classes
 * Overriding classes is not accurate and tends to style non-intended classes
 */
const customStyles = {
  table: {
    style: {
      padding: "0 0 0rem 0", // dropdown from last row can be viewed
      minHeight: "10rem",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#f3f4f6",
      padding: "1rem", // override the row height
      borderBottom: "none",
    },
  },
  rows: {
    style: {
      padding: "1rem", // override the row height
      borderBottomWidth: "5px",
    },
  },
  contextMenu: {
    style: {
      background: "red",
      // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
};

const customStylesOrg = {
  table: {
    style: {
      padding: "0 1rem 0rem 1rem", // dropdown from last row can be viewed
      minHeight: "10rem",
    },
  },
  headRow: {
    style: {
      padding: "1.5rem 1rem", // override the row height
      borderBottom: "2px solid #e5e7eb",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#E4F2FE !important",
      // border: "2px solid #49A9F8 !important",
      cursor: "pointer",
    },
    style: {
      padding: "1rem", // override the row height
      transition: "all 150ms ease-out",
      "&:nth-of-type(even)": {
        background: "#F9F9F9",
      },
      borderRadius: "0.5rem",
      border: "none !important",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
};

const DataTableCustom = (props) =>
  props.portalType === "organisation" ? (
    <DataTableStyledOrg
      customStyles={customStylesOrg}
      {...props}
      highlightOnHover
    />
  ) : (
    <DataTableStyled customStyles={customStyles} {...props} />
  );

const DataTableStyled = styled(DataTable)`
  .rdt_TableCell {
    > div {
      ${"" /* width: 100%; */}
      ${"" /* overflow: visible;  */}
      {/* Allows modals to overlay the parent cell and cells from the left and right side */}
    }
  },
  .rdt_TableCol {
    ${"" /* font-weight: 500; */}
  },
  .rdt_TableRow {
    ${"" /* border-bottom: 1px solid #e5e5e5; */}
  },
`;

const DataTableStyledOrg = styled(DataTable)`
  .rdt_TableCell {
    {/* Targets <div data-tag="allowRowEvents"/> */}
    > div {
      ${"" /* width: 100%; */}
      ${"" /* overflow: visible;  */}
      {/* Allows modals to overlay the parent cell and cells from the left and right side */}
    },
  },
`;

export default DataTableCustom;
