import React from "react";
import FieldWrapper from "./FieldWrapper";

const InputRadio = (props) => {

  const _optionSelector = (value, selection) => {
    if(String(value) === selection) {
      return true;
    }

    return false;
  }

  // console.log(_optionSelector);

  return (
    <FieldWrapper
      checked={_optionSelector(props.value, props.optionSelected)}
      custom={true}
      type={props.type}
      label={props.label}
      optionLabel={props.optionLabel}
      twCss={props.twCss}
      id={props.value}
      optionSelected={props.optionSelected}
    >
      <input
        id={props.value}
        type={props.type}
        name={props.name}
        value={props.value}
      />
    </FieldWrapper>
  );
};

export default InputRadio;
