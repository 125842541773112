import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Components
import VideoProgressBar from "./VideoProgressBar";
import { Button, OverlayModal } from "../../../components/atoms";
import { SplitLayout } from "../../../components/layouts";
import {
  InputDate,
  InputText,
  InputVideoUpload,
  InputSearch,
  InputAsyncSelect,
} from "../../../components/forms";
// Utilities
import { StateView } from "../../../utils/StateView";
import {
  _buildFormData,
  _convertDate,
  _displayFormErrors,
  _validateForm,
} from "../../../utils/helper";
import api from "../../../utils/api";
// import { StateView } from "../../../utils/StateView";

const RightForm = ({
  values,
  _handleChange,
  errors,
  setValues,
  orgId,
  setErrors,
  uploadModalState,
  setInterviewerRefInteracted,
  interviewerRef,
  setIntervieweeRefInteracted,
  intervieweeRef,
}) => {
  const { t } = useTranslation(["video"]);

  const transformOptions = (data) => {
    // console.log("data:", data);

    const transformData = data.map((user) => {
      return {
        label: `${user.name} (${user.email})`,
        name: user.name,
        value: user.id,
        bsn: user.bsn,
        email: user.email,
        id: user.id,
      };
    });
    // console.log("transformData:", transformData);

    return transformData;

    // return transformData.filter((i) =>
    //   i.name.toLowerCase().includes(inputValue.toLowerCase())
    // );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      const formData = {
        name: inputValue,
      };
      api
        .post(`organisation/${orgId}/user/search`, formData)
        .then((response) => {
          const { data } = response.data.result;

          resolve(transformOptions(data, inputValue));
        });
    });

  return (
    <div className="space-y-3 mb-6">
      <InputText
        label={t("details.video_title")}
        type="text"
        // placeholder="Email"
        name="title"
        value={values.title}
        onChange={(e) => _handleChange(e)}
        error={_.get(errors, "title")}
      />
      {/* <InputText
        label={t("details.subtitle")}
        type="text"
        // placeholder="Email"
        name="subtitle"
        value={values.subtitle}
        onChange={(e) => _handleChange(e)}
        error={_.get(errors, "subtitle")}
      /> */}
      {/* defaultValue only works on mount or first render */}
      <InputAsyncSelect
        ref={interviewerRef}
        label={t("details.interviewer")}
        name="interviewer"
        loadOptions={uploadModalState && promiseOptions}
        placeholder="Search user"
        onChange={(evtName, selectOption) => {
          setInterviewerRefInteracted(true);

          setValues({
            ...values,
            interviewer: { ...selectOption },
          });
        }}
        displayPriority={false}
        error={_.get(errors, "interviewer_id")}
      />

      <InputAsyncSelect
        ref={intervieweeRef}
        label={t("details.interviewee")}
        name="interviewee"
        loadOptions={uploadModalState && promiseOptions}
        placeholder="Search user"
        onChange={(evtName, selectOption) => {
          setIntervieweeRefInteracted(true);

          setValues({
            ...values,
            interviewee: { ...selectOption },
          });
        }}
        displayPriority={false}
        error={_.get(errors, "interviewee_id")}
      />

      <InputDate
        todayAndPastOnly
        label={t("details.date")}
        name="recorded_at"
        value={values.recorded_at}
        placeholder="DD-MM-YYYY"
        onChange={(date) => {
          setValues({ ...values, recorded_at: date });
        }}
        error={_.get(errors, "recorded_at")}
      />
      {/* <StateView values={values} title="values" /> */}
    </div>
  );
};

const LeftVideoUpload = ({ values, _uploadFile }) => {
  return (
    <InputVideoUpload
      preview={values.file_preview}
      uploadAction={_uploadFile}
      disableRevokeObjectURL
    />
  );
};

const initialState = (user) => {
  return {
    title: "",
    subtitle: "",
    recorded_at: "",
    file: "",
    file_preview: "",
    interviewer: {
      label: user?.label,
      value: user?.id,
      id: user?.id,
      email: user?.email,
    },
    interviewee: {},
  };
};

const UploadVideo = ({ closeModal, uploadModalState, orgId, userDetails }) => {
  const { t } = useTranslation(["common"]);

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [progressRate, setProgressRate] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const [interviewerRefInteracted, setInterviewerRefInteracted] =
    useState(false);
  const interviewerRef = useRef(null);

  const [intervieweeRefInteracted, setIntervieweeRefInteracted] =
    useState(false);
  const intervieweeRef = useRef(null);

  const defaultInterviewer = {
    label: `${userDetails.name} (${userDetails.email})`,
    value: userDetails.id,
    id: userDetails.id,
    email: userDetails.email,
  };

  const history = useHistory();

  const controller = new AbortController();

  useEffect(() => {
    // Set defaultInterviewer for every load
    interviewerRef.current.setValue(defaultInterviewer);
    setValues(initialState(defaultInterviewer)); // Maintains the initial state after assigning defaultInterviewer

    // Clear Form inputs and errors when modal is closed
    if (uploadModalState === false) {
      URL.revokeObjectURL(values.file_preview);

      if (interviewerRefInteracted) {
        interviewerRef.current.clear();
        /**
         * Setting a Default value for react-select/async
         * everytime the onChange event is triggered
         */
        interviewerRef.current.setValue(defaultInterviewer);
        setInterviewerRefInteracted(false);
      }

      if (intervieweeRefInteracted) {
        intervieweeRef.current.clear();
        setIntervieweeRefInteracted(false);
      }

      // Needs to be below 'interviewerRefInteracted'
      setValues(initialState(userDetails));
      setErrors({});
    }
  }, [uploadModalState]);

  const _handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });

    // if (event.target.name === "interviewee") {
    //   setValues({
    //     ...values,
    //     interviewee: {
    //       obj: { ...values.interviewee.obj },
    //       search: event.target.value,
    //       result: [...values.interviewee.result],
    //     },
    //   });
    // }

    if (event) {
      setErrors({});
    }
  };

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);

    if (percent <= 100) {
      setProgressRate(percent);
    }
  };

  const _submitVideo = () => {
    setErrors({}); // Always clear errors

    // console.log("_transformValues(values):", _transformValues(values));

    // for (var pair of _transformValues(values).entries()) {
    //   console.log(pair[0] + ", " + JSON.stringify(pair[1], null, 2));
    // }

    setShowProgress(true);

    api
      .post(`organisation/${orgId}/video/create`, _transformValues(values), {
        onUploadProgress,
        headers: { disableLoading: true, signal: controller.signal },
      })
      .then((response) => {
        const { data } = response;
        // console.log("response:", response);
        setShowProgress(false);
        setProgressRate(0);
        toast.success(data.message);
        setTimeout(() => closeModal(), 1000);
        setTimeout(() => history.go(0), 1500);
      })
      .catch((error) => {
        const { data } = error;
        // console.log(data);
        toast.error(data.message);
        setShowProgress(false);
        setProgressRate(0);

        if (data.errors) {
          const errorMsg = _displayFormErrors(data.errors);
          setErrors(errorMsg);
        }
      });
  };

  const _transformValues = (formValues) => {
    const { file_preview, interviewer, interviewee, recorded_at, ...rest } =
      formValues;

    const transformedValues = {
      // PHP_SESSION_UPLOAD_PROGRESS: `video-${Date.now()}`,
      interviewer_id: interviewer.id,
      interviewee_id: interviewee.id,
      recorded_at: _convertDate(recorded_at),
      recording_protocol: "",
      duration: "",
      camera_id: "",
      ...rest,
    };

    // console.log("transformedValues:", transformedValues);
    let formData = new FormData();
    _buildFormData(formData, transformedValues);

    return formData;
  };

  const _uploadFile = (file) => {
    // console.log("file:", file);
    file
      ? setValues({
          ...values,
          file_preview: URL.createObjectURL(file),
          file: file,
        })
      : setValues({
          ...values,
          file_preview: "",
          file: "",
        });
  };

  const handleCancelRequest = () => {
    console.log("cancel");
    // console.log('controller:', controller);
    // cancel the request
  };

  return (
    <>
      {showProgress ? (
        <VideoProgressBar
          showModal={showProgress}
          values={values}
          progressRate={progressRate}
          cancelRequest={() => handleCancelRequest}
        />
      ) : (
        <OverlayModal
          type="large"
          showModal={uploadModalState}
          closeModal={closeModal}
          contentClasses={showProgress && "p-0"}
        >
          {/* <StateView
            values={interviewerRefInteracted}
            title="interviewerRefInteracted"
          />
          <StateView
            values={intervieweeRefInteracted}
            title="intervieweeRefInteracted"
          /> */}
          <div className="mb-4">
            <h2 className="">{t("headings.upload_new_video")}</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.
            </p> */}
          </div>
          <SplitLayout
            classes="w-full"
            leftContent={
              <LeftVideoUpload values={values} _uploadFile={_uploadFile} />
            }
            leftContentClasses="w-2/5 pr-4"
            rightContent={
              <RightForm
                orgId={orgId}
                setValues={setValues}
                values={values}
                _handleChange={_handleChange}
                setErrors={setErrors}
                errors={errors}
                userDetails={userDetails}
                uploadModalState={uploadModalState}
                setInterviewerRefInteracted={setInterviewerRefInteracted}
                interviewerRef={interviewerRef}
                setIntervieweeRefInteracted={setIntervieweeRefInteracted}
                intervieweeRef={intervieweeRef}
              />
            }
            rightContentClasses="w-3/5 pl-4"
            noBorder
          />

          <div className="flex justify-center items-center">
            {/* <Button type="tertiary" onClick={null}>
            Previous Video
          </Button> */}
            <Button
              type="primary"
              twClasses="max-w-[10rem]"
              onClick={_submitVideo}
            >
              {t("buttons.submit")}
            </Button>
          </div>
        </OverlayModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.Organisation.org_login.id,
    userDetails: {
      id: state.Auth.userId,
      name: state.Auth.name,
      email: state.Auth.email,
    },
  };
};

export default connect(mapStateToProps, null)(UploadVideo);
