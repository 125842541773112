import React from "react";
import _ from "lodash";
import { FieldWrapper } from ".";

const InputCheckboxes = React.forwardRef((props, ref) => {
  // fixes error of custom prop needs to be converted to string when spread props
  // const { isReadonly, ...filteredProps } = props;
  const { options, values, ...filteredProps } = props;

  const _matchInitialState = (checkboxValue) => {
    for (let i = 0; i < values.length; i++) {
      if (values[i] === checkboxValue) {
        return true;
      }
    }
    return false;
  };

  return (
    <React.Fragment>
      {options.map((option, i) => {
        return (
          <FieldWrapper
            key={i}
            label={option.label}
            // error={_.isString(props.error) && props.error}
            type={props.type}
            title={option.description}
          >
            <input
              className=""
              ref={ref}
              checked={_matchInitialState(option.value)}
              name={option.value}
              value={option.value}
              {...filteredProps}
            ></input>
          </FieldWrapper>
        );
      })}

      {_.isString(props.error) && (
        <div className="form-error text-red-500 text-sm mt-2">
          {props.error}
        </div>
      )}
    </React.Fragment>
  );
});

export default InputCheckboxes;
