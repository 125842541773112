import * as actionTypes from "../actions/action_types";
import produce from "immer";

const INITIAL_STATE = {
  access_token: null,
  userType: null,
  email: null,
  name: null,
  first_name: null,
  last_name: null,
  initials: null,
  userId: null,
  is2faEnabledFromProfile: false,
  isEnabled2FA: 0,
  isVerified2FA: 0,
  qrCode2FA: null,
  secret2FA: null,
  token2FA: null,
  photo: null,
  isTokenExpired: false,
  type2FA: null,
};

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.REQUEST_LOGIN:
      return produce(state, (draft) => {
        draft.access_token = action.data.access_token;
        draft.userType = action.data.userType;
        draft.email = action.data.email;
        draft.name = action.data.name;
        draft.first_name = action.data.first_name;
        draft.last_name = action.data.last_name;
        draft.initial_name = action.data.initial_name;
        draft.userId = action.data.userId;
        draft.isEnabled2FA = action.data.isEnabled2FA;
        draft.isVerified2FA = action.data.isVerified2FA;
        draft.qrCode2FA = action.data.qrCode2FA;
        draft.secret2FA = action.data.secret2FA;
        draft.photo = action.data.photo;
        draft.type2FA = action.data.type2FA;
      });

    case actionTypes.REQUEST_LOGOUT:
      return INITIAL_STATE;

    case actionTypes.AUTHENTICATE_2FA:
      return produce(state, (draft) => {
        draft.is2faEnabledFromProfile = false; // If enabled from Profile page, bring back to false
        draft.token2FA = action.payload;
      });

    case actionTypes.ENABLE_2FA:
      return produce(state, (draft) => {
        // console.log("action.payload:", action.payload);
        draft.isEnabled2FA = action.payload.isEnabled2FA;
        draft.qrCode2FA = action.payload.qrCode2FA;
        draft.secret2FA = action.payload.secret2FA;
        draft.type2FA = action.payload.type2FA;
      });

    case actionTypes.IS_2FA_ENABLED_FROM_PROFILE:
      return produce(state, (draft) => {
        draft.is2faEnabledFromProfile = action.payload;
      });

    case actionTypes.IS_TOKEN_EXPIRED:
      return produce(state, (draft) => {
        draft.isTokenExpired = action.payload;
      });

    case actionTypes.VERIFY_2FA:
      return produce(state, (draft) => {
        draft.isVerified2FA = action.payload;
      });

    case actionTypes.SET_LOGIN_PIC:
      return produce(state, (draft) => {
        draft.photo = action.payload;
      });

    case actionTypes.DISABLE_2FA:
      return produce(state, (draft) => {
        draft.isEnabled2FA = 0;
        draft.token2FA = null;
        draft.isVerified2FA = 0;
      });

    default:
      return state;
  }
}
