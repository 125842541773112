import * as actionTypes from "../actions/action_types";
import produce from "immer";

const INITIAL_STATE = {
  /*
    Super Admin - Superadmin portal + Admin portal only (All organisations)
    Admin - Admin portal + User portal (Linked organisation(s))
    User - User portal (Linked organisation(s))
  */
  org_portal_view: "user", // Or admin (default: user)
  org_login: {
    id: null,
    name: null,
    shortcode: null,
    logo: null,
    orgUserType: null,
    role: {
      guard_name: null,
      rights: [],
      role_name: null,
    },
  },
};

export default function organisationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOGIN_ORGANISATION:
      return produce(state, (draft) => {
        // console.log('state login Org:', state);
        draft.org_login.id = action.payload.id;
        draft.org_login.name = action.payload.name;
        draft.org_login.shortcode = action.payload.shortcode;
        draft.org_login.logo = action.payload.logo;
        draft.org_login.orgUserType = action.payload.orgUserType;
        draft.org_login.role = action.payload.role;
      });

    case actionTypes.LOGOUT_ORGANISATION:
      return INITIAL_STATE;

    case actionTypes.TOGGLE_ORGANISATION_PORTAL_VIEW:
      return produce(state, (draft) => {
        draft.org_portal_view = action.payload;
      });

    case actionTypes.UPDATE_ROLE_AND_RIGHTS:
      return produce(state, (draft) => {
        draft.org_login.role.guard_name = action.payload.guard_name;
        draft.org_login.role.role_name = action.payload.role_name;
        draft.org_login.role.rights = action.payload.rights;
      });

    default:
      return state;
  }
}
