import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { FieldWrapperInline, InputReadOnly } from ".";
import { iconPencil_White, iconX_White, iconPencil_Navy } from "../atoms/Icons";
import { StateView } from "../../utils/StateView";
import { toast } from "react-toastify";

const InlineInputTags = (props) => {
  const {
    isReadonly,
    setValues,
    values,
    displayValues,
    asyncFn,
    editMode,
    ...filteredProps
  } = props;

  const inlineInputEl = useRef();

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused) {
      inlineInputEl.current.focus();
      // console.log("focus");
    }
  }, [isFocused]);

  const _handleTagClickDelete = (e, value) => {
    setValues({
      ...values,
      tags: [...values.tags.filter((el) => el !== value)],
    });
  };

  const _keyDownHandler = (e) => {
    const evtTargetName = e.target.name;

    if (e.key === "Enter") {
      // Remove white spaces
      const valueNoSpace = e.target.value.replace(/\s+/g, "");

      if (values.tags.includes(valueNoSpace)) {
        toast.error("Tag already exists");
      } else {
        // Separate words with a "," into an array
        const modifiedValue = valueNoSpace.split(",");

        // Don't accept empty strings
        if (!modifiedValue.includes("")) {
          const appendedTags = [...values.tags, ...modifiedValue];
          setValues({ ...values, tags: appendedTags });
          asyncFn(appendedTags, evtTargetName); //
          setIsFocused(false);
          inlineInputEl.current.value = ""; // Clear value
          inlineInputEl.current.blur(); // Out of focus
        } else {
          inlineInputEl.current.blur(); // Out of focus
          toast.info("Don't leave it empty");
        }
      }
    }
  };

  // useEffect(() => {
  //   if (isFocused) {
  //     if (values.tags.length < displayValues.tags.length) {
  //       console.log("values total:", values.tags);
  //       asyncFn(values.tags, "tags");
  //       inlineInputEl.current.focus();
  //     }
  //   }
  // }, [values.tags]);

  const _handleCloseBtn = () => {
    if (isFocused) {
      if (values.tags.length < displayValues.tags.length) {
        // console.log("values total:", values.tags);
        asyncFn(values.tags, "tags");
      }
      setIsFocused(false);
    }
  };

  const inputClasses = `outline-8 outline-red-400 block px-1 py-2 h-7 rounded-sm focus-visible:outline-pictonblue bg-white text-primary`;

  return (
    <React.Fragment>
      {/* <StateView values={isFocused} title="isFocused" /> */}
      <FieldWrapperInline
        label={props.label}
        error={_.isString(props.error) && props.error}
        alignment={props.alignment}
      >
        {isFocused && (
          <>
            <div className="mt-1 flex items-center justify-between">
              <input
                className={inputClasses}
                ref={inlineInputEl}
                {...filteredProps}
                placeholder={props.placeholder}
                onKeyDown={(e) => _keyDownHandler(e)}
              />
              <div
                className="w-8 h-8 rounded-full flex items-center justify-center bg-primary-active hover:opacity-50 hover:cursor-pointer"
                onClick={() => _handleCloseBtn()}
              >
                <img src={iconX_White} className="w-1/2" alt="" />
              </div>
            </div>
            <i className="text-xs opacity-50 mt-1">
              Press enter. More than 1 tag, separate by ( , )
            </i>
          </>
        )}
        <div className="mt-2 flex items-start min-h-[1.75rem]">
          <div className="flex items-center flex-wrap gap-1">
            {values.tags.length !== 0 &&
              values.tags !== [""] &&
              values.tags.map((value, i) => {
                return (
                  <div
                    key={`tag-${i}`}
                    className="font-medium px-3 py-[0.15rem] bg-pictonblue rounded-sm flex items-center"
                  >
                    <span>{value}</span>
                    {isFocused && (
                      <img
                        key={`tag-close-${i}`}
                        src={iconX_White}
                        className="w-3 ml-2 hover:cursor-pointer"
                        alt=""
                        onClick={(e) => _handleTagClickDelete(e, value)}
                      />
                    )}
                  </div>
                );
              })}
          </div>
          {editMode && !isFocused && (
            <div
              className={`w-10 min-w-[2.5rem] h-7 relative hover:cursor-pointer group`}
              onClick={() => setIsFocused(true)}
            >
              <img
                className={`w-full absolute group-hover:opacity-50 ${
                  values.tags.length === 0
                    ? "left-0 -translate-x-[30%]"
                    : "left-1/2 -translate-x-[50%]"
                }  top-1/2 -translate-y-[55%]`}
                src={props.mode === "light" ? iconPencil_Navy : iconPencil_White}
                alt=""
              />
            </div>
          )}
        </div>
      </FieldWrapperInline>
    </React.Fragment>
  );
};

export default InlineInputTags;
