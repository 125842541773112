import React, { Suspense, lazy, useCallback, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";

// Utilities
import { ProtectedRoute, AuthRoute } from "./components/router";
import { fontSizeOptions } from "./utils/constants";

// UI
import { Loader } from "./components/atoms";
import { LayoutWrapper } from "./components/layouts";
import TokenExpirationModal from "./screens/Account/TokenExpirationModal";
/**
 * Pages
 */
// Public
const Login = lazy(() => import("./screens/Public/Login"));
const ForgotPassword = lazy(() => import("./screens/Public/ForgotPassword"));
const ResetPassword = lazy(() => import("./screens/Public/ResetPassword"));
const SignUp = lazy(() => import("./screens/Public/SignUp"));
// Dashboard
const Dashboard = lazy(() => import("./screens/Dashboard/Dashboard"));
// Organisation
const OrganisationsTable = lazy(() =>
  import("./screens/Organisation/OrganisationsTable")
);
const NewOrganisation = lazy(() =>
  import("./screens/Organisation/NewOrganisation")
);
const OrganisationPortal = lazy(() =>
  import("./screens/Organisation/OrganisationPortal")
);
const ViewOrganisation = lazy(() =>
  import("./screens/Organisation/ViewOrganisation")
);
const EditOrganisation = lazy(() =>
  import("./screens/Organisation/EditOrganisation")
);
// Video
const AllVideos = lazy(() => import("./screens/Video/AllVideos"));
const OrganisationVideos = lazy(() =>
  import("./screens/Video/OrganisationVideos")
);
const ViewVideo = lazy(() => import("./screens/Video/ViewVideo"));
const VideoPlayerPage = lazy(() =>
  import("./screens/Video/VideoPlayerPage/VideoPlayerPage")
);
// Priority
const Priority = lazy(() => import("./screens/Priority/PriorityOverview"));
// Shared
const SharedVideos = lazy(() => import("./screens/Shared/SharedVideos"));
// Recent
const RecentVideos = lazy(() => import("./screens/Recent/RecentVideos"));
// Starred
const StarredVideos = lazy(() => import("./screens/Starred/StarredVideos"));
// Camera
const CamerasTable = lazy(() => import("./screens/Camera/CamerasTable"));
const NewCamera = lazy(() => import("./screens/Camera/NewCamera"));
const ViewCamera = lazy(() => import("./screens/Camera/ViewCamera"));
const EditCamera = lazy(() => import("./screens/Camera/EditCamera"));
// User
const UsersTable = lazy(() => import("./screens/User/UsersTable"));
const NewUser = lazy(() => import("./screens/User/NewUser"));
const ViewUser = lazy(() => import("./screens/User/ViewUser"));
const EditUser = lazy(() => import("./screens/User/EditUser"));
// Roles
const Roles = lazy(() => import("./screens/Roles/Roles"));
const NewRole = lazy(() => import("./screens/Roles/NewRole"));
// Settings
const Settings = lazy(() => import("./screens/Settings/Settings"));
// Account
const AccessOptions = lazy(() => import("./screens/Account/AccessOptions"));
const Verify2FA = lazy(() => import("./screens/Account/Verify2FA"));
const Profile = lazy(() => import("./screens/Account/Profile"));
const EditProfile = lazy(() => import("./screens/Account/EditProfile"));
const ChangePassword = lazy(() => import("./screens/Account/ChangePassword"));
// Activity
const ActivityTable = lazy(() => import("./screens/Activity/ActivityTable"));

/**
 * React Router v5 Tip:
 * - The more specific routes i.e. the longer the paths, should be ordered top of the list followed by the less specific ones
 * - Simply changing routes order without following above can prevent it from rendering the page component
 */

const SharedRoutes = () => {
  return (
    <React.Fragment>
      <Route
        exact
        path="/accessOptions"
        render={(props) => <AccessOptions {...props} />}
      />
      <AuthRoute exact path="/verify2fa/:token" component={Verify2FA} />
      {/* <Route
        exact
        path="/terms-and-conditions"
        render={(props) => <AccessOptions {...props} />}
      /> */}
    </React.Fragment>
  );
};

const RedirectFromOrgRoutesIfNotLoggedIn = (orgId) => {
  const history = useHistory();

  const viewingOrgRoute = window.location.pathname.includes("/org/");

  /**
   * Must consider redirect conditions for the user when:
   * 1) From accessing Org Routes -> App Routes when NOT LOGGED INTO Org Portal
   * 2) From accessing App Routes -> Org Routes when LOGGED INTO Org Org Portal
   */
  if (!orgId) {
    if (viewingOrgRoute) {
      console.log("viewing org path when not logged in");
      history.push("/");
    }
  }
};

const SuperadminSwitch = ({ orgId }) => {
  // console.log("Admin Switch");
  RedirectFromOrgRoutesIfNotLoggedIn(orgId);

  return (
    <Switch>
      {/*
       * ORGANISATION LAYER
       */}
      <ProtectedRoute exact path="/org/settings" component={Settings} />
      <ProtectedRoute exact path="/org/cameras" component={CamerasTable} />
      <ProtectedRoute exact path="/org/videos" component={OrganisationVideos} />
      <ProtectedRoute exact path="/org/users/new/1" component={NewUser} />
      <ProtectedRoute exact path="/org/users/:id/edit" component={EditUser} />
      <ProtectedRoute exact path="/org/users/:id" component={ViewUser} />
      <ProtectedRoute exact path="/org/users" component={UsersTable} />
      <ProtectedRoute exact path="/org/roles" component={Roles} />
      <ProtectedRoute exact path="/org/profile/edit" component={EditProfile} />
      <ProtectedRoute exact path="/org/profile" component={Profile} />
      <ProtectedRoute exact path="/org/activity" component={ActivityTable} />
      <ProtectedRoute exact path="/org/" component={Dashboard} />
      <ProtectedRoute
        exact
        path="/org/changePassword"
        component={ChangePassword}
      />
      {/* APP LAYER - Super Admin */}
      <ProtectedRoute exact path="/users/new/1" component={NewUser} />
      <ProtectedRoute exact path="/users/:id/edit" component={EditUser} />
      {/* <ProtectedRoute exact path="/users/:id" component={ViewUser} /> */}
      <ProtectedRoute exact path="/users" component={UsersTable} />
      <ProtectedRoute
        exact
        path="/organisations/new/1"
        component={NewOrganisation}
      />
      <ProtectedRoute
        exact
        path={"/organisations/:id/edit"}
        component={EditOrganisation}
      />
      <ProtectedRoute
        exact
        path={"/organisations/:id"}
        component={ViewOrganisation}
      />
      <ProtectedRoute
        exact
        path="/organisations"
        component={OrganisationsTable}
      />
      {/* <ProtectedRoute
        exact
        path="/organisation-portal"
        component={OrganisationPortal}
      /> */}
      <ProtectedRoute exact path="/changePassword" component={ChangePassword} />
      <ProtectedRoute exact path="/activity" component={ActivityTable} />
      <ProtectedRoute exact path="/videos/:id" component={ViewVideo} />
      <ProtectedRoute exact path="/videos" component={AllVideos} />
      <ProtectedRoute exact path="/cameras/new/1" component={NewCamera} />
      <ProtectedRoute exact path="/cameras/:id/edit" component={EditCamera} />
      <ProtectedRoute exact path="/cameras/:id" component={ViewCamera} />
      <ProtectedRoute exact path="/cameras" component={CamerasTable} />
      <ProtectedRoute exact path="/profile/edit" component={EditProfile} />
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path={["/", "/dashboard"]} component={Dashboard} />
      <SharedRoutes />
    </Switch>
  );
};

// Trigger only for UserSwitch
/**
 * This causes rendering issues, need to declare both at UserSwitch and here to
 * have more specificity in rendering the routes on the page
 */
const AdminRoutes = () => {
  return (
    <React.Fragment>
      <ProtectedRoute
        exact
        path="/org/changePassword"
        component={ChangePassword}
      />
      <ProtectedRoute exact path="/org/settings" component={Settings} />
      <ProtectedRoute exact path="/org/users/new/1" component={NewUser} />
      <ProtectedRoute exact path="/org/users/:id/edit" component={EditUser} />
      <ProtectedRoute exact path="/org/users/:id" component={ViewUser} />
      <ProtectedRoute exact path="/org/users" component={UsersTable} />
      <ProtectedRoute exact path="/org/cameras" component={CamerasTable} />
      <ProtectedRoute exact path="/org/roles/new/1" component={NewRole} />
      <ProtectedRoute exact path="/org/roles" component={Roles} />
      <ProtectedRoute exact path="/org/activity" component={ActivityTable} />
      {/* Admin Videos page */}
      <ProtectedRoute exact path="/org/videos" component={OrganisationVideos} />
      <ProtectedRoute exact path="/org/profile/edit" component={EditProfile} />
      <ProtectedRoute exact path="/org/profile" component={Profile} />
      <ProtectedRoute exact path="/org/" component={Dashboard} />
    </React.Fragment>
  );
};

const UserSwitch = ({ orgPortalView, orgId }) => {
  // console.log("orgId:", orgId);

  RedirectFromOrgRoutesIfNotLoggedIn(orgId);

  // console.log("orgPortalView USER SWITCH:", orgPortalView);
  return (
    <Switch>
      {/* <Route
        exact
        path="/"
        render={() => {
          return <Redirect to="/organisation-portal" />;
        }}
      /> */}
      {/* 
        ORGANISATION LAYER 
      */}
      {/* Admin Routes */}
      {orgPortalView === "admin" ? <AdminRoutes /> : null}
      <ProtectedRoute
        exact
        path="/org/changePassword"
        component={ChangePassword}
      />
      <ProtectedRoute
        exact
        path="/org/starred/play/:id"
        component={VideoPlayerPage}
      />
      <ProtectedRoute exact path="/org/starred" component={StarredVideos} />
      <ProtectedRoute
        exact
        path="/org/recent/play/:id"
        component={VideoPlayerPage}
      />
      <ProtectedRoute exact path="/org/recent" component={RecentVideos} />
      <ProtectedRoute
        exact
        path="/org/priority/play/:id"
        component={VideoPlayerPage}
      />
      <ProtectedRoute exact path="/org/priority" component={Priority} />
      <ProtectedRoute
        exact
        path="/org/shared/play/:id"
        component={VideoPlayerPage}
      />
      <ProtectedRoute exact path="/org/shared" component={SharedVideos} />
      <ProtectedRoute
        exact
        path="/org/videos/edit/:id"
        component={VideoPlayerPage}
      />
      <ProtectedRoute
        exact
        path="/org/videos/play/:id"
        component={VideoPlayerPage}
      />
      <ProtectedRoute exact path="/org/profile/edit" component={EditProfile} />
      <ProtectedRoute exact path="/org/profile" component={Profile} />
      <ProtectedRoute exact path="/org/videos" component={OrganisationVideos} />
      {/* User Videos page */}
      {/* 
        APP LAYER 
      */}
      <ProtectedRoute exact path="/changePassword" component={ChangePassword} />
      <ProtectedRoute exact path="/profile/edit" component={EditProfile} />
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path="/" component={OrganisationPortal} />
      <SharedRoutes />
    </Switch>
  );
};

const PublicSwitch = () => {
  // console.log("Public Switch");

  return (
    <Switch>
      <Route
        exact
        path={["/", "/login"]}
        render={(props) => <Login {...props} />}
      />
      <Route
        exact
        path="/forgotpassword"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route
        exact
        path="/resetPassword/:token"
        render={(props) => <ResetPassword {...props} />}
      />
      <Route exact path="/signup" render={(props) => <SignUp {...props} />} />
    </Switch>
  );
};

const AppRouter = (props) => {
  const { loader, history, Auth, Access, orgPortalView, orgId } = props;

  // console.log('props:', props);

  const listOfFontSizes = fontSizeOptions.map((size) => {
    return size.value;
  });

  const _scaleFontSizes = (domEl, arrOfSize, selectedSize) => {
    arrOfSize.forEach((size) => {
      domEl.classList.remove(`scale-text-${size}`);
    });

    if (selectedSize !== "100") {
      return domEl.classList.add(`scale-text-${selectedSize}`);
    } else {
      return domEl.classList.remove(`scale-text-${selectedSize}`);
    }
  };

  const _enableDarkMode = (domEl, selectedMode) => {
    if (selectedMode === "dark") {
      domEl.classList.add("dark");
    } else {
      domEl.classList.remove("dark");
    }
  };

  const { font_size, contrast_mode } = Access; // Get props from Access

  useEffect(
    () => {
      const bodyEl = document.body;

      _scaleFontSizes(bodyEl, listOfFontSizes, font_size);
      _enableDarkMode(bodyEl, contrast_mode);
    },
    // eslint-disable-next-line
    [font_size, contrast_mode]
  );

  const { userType, access_token, token2FA, isEnabled2FA, isVerified2FA } =
    Auth; // Get props from Auth

  const NavigationContainer = useCallback(
    (navProps) => {
      // Switch navigation container based on user authentication and userType
      switch (navProps.userType) {
        case "super_admin":
          return <SuperadminSwitch orgId={navProps.orgId} />;
        case "admin":
          return (
            <UserSwitch
              orgPortalView={navProps.orgPortalView}
              orgId={navProps.orgId}
            />
          );
        case "user":
          return (
            <UserSwitch
              orgPortalView={navProps.orgPortalView}
              orgId={navProps.orgId}
            />
          );
        default:
          return <PublicSwitch />;
      }
    },
    // eslint-disable-next-line
    [userType, orgPortalView]
  );

  // Create exceptions for dynamic paths
  const _dynamicExceptionPaths = () => {
    const pathArray = window.location.pathname.split("/");
    const secondLevelPath = pathArray[1]; // get static path
    const thirdLevelPath = pathArray[2]; // get dynamic path

    //  Exception for '/resetPassword/:token
    if (secondLevelPath === "resetPassword" && thirdLevelPath.length > 0) {
      return true;
    }

    return false;
  };

  // start: check if user accessing protected routes when login fail
  const exceptionPaths = ["/", "/forgotpassword", "/signup"];
  let location = window.location;

  // If the URL paths don't match the Exceptions paths, redirects to login page
  // Not logged in App (no access token)
  if (
    access_token === null &&
    exceptionPaths.indexOf(location.pathname) < 0 &&
    !_dynamicExceptionPaths()
  ) {
    console.log("A");
    history.push("/");
  } else {
    // When logged in (got access token)
    // console.log("B");
    // This redirects to login page once user's access token is removed (i.e. logged out)
    if (
      access_token === null &&
      exceptionPaths.indexOf(location.pathname) < 0 &&
      !_dynamicExceptionPaths()
    ) {
      console.log("C");
      history.push("/");
    }
  }
  // end

  return (
    <Suspense fallback={<Loader />}>
      <ConnectedRouter history={history}>
        <LayoutWrapper
          history={history}
          userType={userType}
          token2FA={token2FA}
          access_token={access_token}
          isEnabled2FA={isEnabled2FA}
          isVerified2FA={isVerified2FA}
        >
          {loader && <Loader />}
          <NavigationContainer
            userType={userType}
            orgPortalView={orgPortalView}
            orgId={orgId}
          />
        </LayoutWrapper>
        <TokenExpirationModal />
      </ConnectedRouter>
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.App.loader,
    Auth: state.Auth,
    Access: state.Access,
    orgPortalView: state.Organisation.org_portal_view,
    orgId: state.Organisation.org_login.id,
  };
};

export default connect(mapStateToProps, null)(AppRouter);
