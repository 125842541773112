import {
  iconUsers_Navy,
  iconNodesLeft_White,
  iconOdometer_Navy,
  iconCamera_Navy,
  iconNodesUp_Navy,
  iconPlay_Navy,
  iconPlay_White,
  iconSettings_Navy,
  iconClock_Navy,
  iconClock_White,
  iconShapes_Navy,
  iconStar_White,
  iconTickBadge_White,
} from "../components/atoms/Icons";

const appNavLinks = {
  super_admin: [
    {
      name: "Dashboard",
      value: "dashboard",
      url: "/",
      icon: iconOdometer_Navy,
    },
    // {
    //   name: "Organisation Portal",
    //   url: "/organisation-portal",
    //   icon: iconBuildings_Navy,
    // },
    {
      name: "Videos",
      value: "videos",
      url: "/videos",
      icon: iconPlay_Navy,
    },
    {
      name: "Cameras",
      value: "cameras",
      url: "/cameras",
      icon: iconCamera_Navy,
    },
    {
      name: "Users",
      value: "users",
      url: "/users",
      icon: iconUsers_Navy,
    },
    {
      name: "Organisations",
      value: "organisations",
      url: "/organisations",
      icon: iconNodesUp_Navy,
    },
    // {
    //   name: "Settings",
    //   url: "/settings",
    //   icon: iconSettings_Navy,
    // },
    {
      name: "Activity",
      value: "activity",
      url: "/activity",
      icon: iconClock_Navy,
    },
  ],
  // user_admin: [
  //   {
  //     name: "Organisation Portal",
  //     url: "/organisation-portal",
  //     icon: iconBuildings_White,
  //   },
  // ],
};

const orgNavLinks = {
  admin: [
    {
      name: "Dashboard",
      value: "dashboard",
      url: "/org/",
      icon: iconOdometer_Navy,
    },
    {
      name: "Videos",
      value: "videos",
      url: "/org/videos",
      icon: iconPlay_Navy,
    },
    {
      name: "Cameras",
      value: "cameras",
      url: "/org/cameras",
      icon: iconCamera_Navy,
    },
    {
      name: "Users",
      value: "users",
      url: "/org/users",
      icon: iconUsers_Navy,
    },
    {
      name: "Roles",
      value: "roles",
      url: "/org/roles",
      icon: iconShapes_Navy,
    },
    // {
    //   name: "Subscription",
    //   url: "/org/subscription",
    //   icon: iconPricetag_Navy,
    // },
    {
      name: "Organisation",
      value: "organisation",
      url: "/org/settings",
      icon: iconSettings_Navy,
    },
    {
      name: "Activity",
      value: "activity",
      url: "/org/activity",
      icon: iconClock_Navy,
    },
  ],
  user: [
    {
      name: "My videos",
      value: "my-videos",
      url: "/org/videos",
      icon: iconPlay_White,
    },
    {
      name: "Starred",
      value: "starred",
      url: "/org/starred",
      icon: iconStar_White,
    },
    {
      name: "Recent",
      value: "recent",
      url: "/org/recent",
      icon: iconClock_White,
    },
    {
      name: "Shared",
      value: "shared",
      url: "/org/shared",
      icon: iconNodesLeft_White,
    },
    {
      name: "Priority",
      value: "priority",
      url: "/org/priority",
      icon: iconTickBadge_White,
    },
    // {
    //   name: "Bin",
    //   url: "/org/bin",
    //   icon: iconBin_White,
    // },
  ],
};

export { orgNavLinks, appNavLinks };
