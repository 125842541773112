import React from "react";
import { Link } from "react-router-dom";

const MainLink = ({ pathname, prevLocation, children, ...props }) => {
  // console.log("props:", props);
  // console.log("prevLocation:", prevLocation);
  return (
    <Link
      to={{
        pathname: pathname,
        state: {
          prevLocation: prevLocation,
        },
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

export default MainLink;
