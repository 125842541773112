import * as actionTypes from "./action_types";

export const login = (data) => {
  return {
    type: actionTypes.REQUEST_LOGIN,
    data,
  };
};

export const logout = () => {
  return {
    type: actionTypes.REQUEST_LOGOUT,
  };
};

export const verify2fa = (bool) => {
  return {
    type: actionTypes.VERIFY_2FA,
    payload: bool,
  };
};

export const enable2fa = (data) => {
  return {
    type: actionTypes.ENABLE_2FA,
    payload: data,
  };
};

export const authenticate2fa = (token) => {
  return {
    type: actionTypes.AUTHENTICATE_2FA,
    payload: token,
  };
};

export const setLoginPic = (blobPic) => {
  return {
    type: actionTypes.SET_LOGIN_PIC,
    payload: blobPic,
  };
};

export const disable2fa = () => {
  return {
    type: actionTypes.DISABLE_2FA,
  };
};

export const toggle2faIsEnabledFromProfile = (bool) => {
  return {
    type: actionTypes.IS_2FA_ENABLED_FROM_PROFILE,
    payload: bool,
  };
};

export const isTokenExpired = (bool) => {
  return {
    type: actionTypes.IS_TOKEN_EXPIRED,
    payload: bool,
  };
};

export const logoutSystem = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_LOGOUT });
    dispatch({ type: actionTypes.LOGOUT_ORGANISATION });
  };
};
