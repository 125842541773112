import React, { useState, useRef, useEffect } from "react";
import { iconOptions_Navy } from "../Icons";
import { _useOnClickOutside } from "../../../utils/helper";
// import { StateView } from "../../../utils/StateView";
// import { Link } from "react-router-dom";

const OptionsDropdown = ({
  options,
  match,
  location,
  id,
  title,
  clickHandler,
  ...props
}) => {
  const dropdownRef = useRef(); // To ref element for '_useOnClickOutside' to close when clicked out of the referenced element

  const [toggleOptions, setToggleOptions] = useState(false);
  const [adjustPosY, setAdjustPosY] = useState(0);

  _useOnClickOutside(dropdownRef, () => setToggleOptions(false));

  useEffect(() => {
    /**
     * Triggers dynamic positioning of dropdown for table records closer to the
     * bottom edge of the viewport and ajust it higher
     */
    if (toggleOptions) {
      const dropdownEl = dropdownRef.current;
      const dropdownRect = dropdownEl.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const bottomPointDropdown = dropdownRect.bottom;
      const dataTableBodyEl = document.getElementsByClassName("rdt_TableBody");

      // If Datatable 'rdt_TableBody' pagination exists in the DOM
      if (dataTableBodyEl.length > 0) {
        const dataTableBodyElRect = dataTableBodyEl[0].getBoundingClientRect();
        const dataTableBodyElRectBottom = dataTableBodyElRect.bottom;

        // If the bottom tip of Datatable is in view of the current viewport
        if (dataTableBodyElRectBottom <= viewportHeight) {
          // If the bottom tip's position of the dropwdown is lower than or equals to the bottom tip's position of the "rdt_TableBody" element
          if (bottomPointDropdown >= dataTableBodyElRectBottom) {
            const slightBumpY = 10; // Give a slight bump (Y-axis) from the bottom of the "rdt_TableBody" element
            let bottomDiff = bottomPointDropdown - dataTableBodyElRectBottom;

            setAdjustPosY(bottomDiff + slightBumpY);
          }
        } else {
          const slightBumpY = 10; // Give a slight bump (Y-axis) from the bottom of the viewport

          /**
           * The bottom tip of the dropdown is tracked whereby if it exceeds
           * the the viewport height, then it is adjusted higher based on
           * the difference bet2een them (and a slight bump of UX purposes)
           */
          if (bottomPointDropdown >= viewportHeight) {
            let bottomDiff = bottomPointDropdown - viewportHeight;

            setAdjustPosY(bottomDiff + slightBumpY);
          }
        }
      }
    }

    return () => {
      /**
       * /////// NECESSSARY ///////
       * This resets the position of thde dropdown back to default.
       * Without this, the onclick event will always capture values
       * based on the instance you clicked on it which can cause
       * 'adjustPosY' to have varied values without resetting it.
       */
      setAdjustPosY(0);
    };
  }, [toggleOptions, adjustPosY]);

  const _preventEventBubbling = (event) => {
    event.stopPropagation();
  };

  return (
    options.length > 0 && (
      <>
        {/* <StateView values={adjustPosY} title="adjustPosY" /> */}
        <div
          className="relative w-12 flex justify-center items-center"
          onClick={(e) => _preventEventBubbling(e)}
        >
          <img
            src={iconOptions_Navy}
            alt=""
            className="w-full hover:opacity-50 transform transition-opacity hover:cursor-pointer"
            onClick={() => setToggleOptions(!toggleOptions)}
          />
          <div
            ref={dropdownRef}
            className={`absolute ${
              toggleOptions ? "block" : "hidden"
            } transition-all z-10 w-auto whitespace-nowrap top-0 right-0 transform flex flex-col bg-white shadow-md border-t border-gray-100 rounded`}
            style={{ transform: `translateY(${-adjustPosY}px)` }}
          >
            {options.map((option, i) => {
              /**
               * Below is to anticipate open video in new tab
               */
              {
                /* if (option.value === "view" || option.value === "edit") {
                return (
                  <Link
                    key={i}
                    to={{
                      pathname: `${match.url}/play/${id}`,
                      state: {
                        prevLocation: location.pathname,
                        autoEditMode: option.value === "edit" ? true : false,
                      },
                    }}
                    className={`px-4 py-2 flex items-center hover:bg-pictonblue-highlight hover:text-pictonblue transition-all cursor-pointer ${
                      option.disable && "pointer-events-none opacity-25"
                    }`}
                    onClick={(e) => clickHandler(id, option.value, title)}
                  >
                    {option.label}
                  </Link>
                );
              } else { */
              }
              return (
                <div
                  key={i}
                  className={`px-4 py-2 flex items-center hover:bg-pictonblue-highlight hover:text-pictonblue transition-all cursor-pointer ${
                    option.disable && "pointer-events-none opacity-25"
                  }`}
                  onClick={(e) => clickHandler(id, option.value, title)}
                >
                  {option.label}
                </div>
              );
              {
                /* } */
              }
            })}
          </div>
        </div>
      </>
    )
  );
};

export default OptionsDropdown;
