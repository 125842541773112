import axios from "axios";

// redux
import { store } from "./../redux/store";
import { loading, isTokenExpired } from "../redux/actions";

const APIHost = () => {
  let host = process.env.REACT_APP_BASEURL;

  return host;
};

// create axios instance
const api = axios.create({
  baseURL: APIHost(),
});

api.interceptors.request.use(
  function (config) {
    if (config.headers.disableLoading) {
      store.dispatch(loading(false));
    } else {
      store.dispatch(loading(true));
    }

    const token = store.getState().Auth.access_token || null;
    const token2fa = store.getState().Auth.token2FA || null;

    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Only for user related routes
    if (
      config.url.includes("user") ||
      config.url.includes("organisation") ||
      config.url.includes("superadmin") ||
      config.url.includes("auth") ||
      config.url.includes("config")
    ) {
      config.headers["2FA-Authorization"] = token2fa;
    }

    // console.log(`Request, ${config.url}`, config)
    return config;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // console.log('Response', response)

    if (!response.config.headers.disableLoading) {
      store.dispatch(loading(false));
    }

    return response;
  },
  (error) => {
    // console.error('Error', error.response)

    // Handle Token Expiration
    if (error.response.status === 401) {
      store.dispatch(isTokenExpired(true));
    }

    if (!error.response.config.headers.disableLoading) {
      store.dispatch(loading(false));
    }

    // if (error.config.url !== "auth/login") {
    //   if (error.response.status === 401) {
    //     store.dispatch(logoutSuccess())
    //     window.location = "/"
    //   }
    // }

    return Promise.reject(error.response);
  }
);

export default api;
