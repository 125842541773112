import * as actionTypes from "../actions/action_types";
// import produce from "immer";

const INITIAL_STATE = {
  login_prompt: {
    enabled: true,
    toggle_prompt: false,
  },
  font_size: "100",
  contrast_mode: "light",
  language: "nl",
};

const accessReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_ACCESS_OPTIONS: {
      const { login_prompt, ...rest } = action.payload;

      if (login_prompt) {
        return {
          ...rest,
          login_prompt: { ...state.login_prompt, toggle_prompt: true },
        };
      }
      return {
        ...rest,
        login_prompt: {
          enabled: false,
          toggle_prompt: false,
        },
      };
    }

    case actionTypes.FONT_SIZE:
      return {
        ...state,
        font_size: action.payload,
      };

    case actionTypes.CONTRAST_MODE:
      return {
        ...state,
        contrast_mode: action.payload,
      };

    case actionTypes.LANGUAGE_OPTION:
      return {
        ...state,
        language: action.payload,
      };

    case actionTypes.TOGGLE_ACCESS_LOGIN_PROMPT:
      return {
        ...state,
        login_prompt: {
          ...state.login_prompt,
          toggle_prompt: action.payload,
        },
      };

    default:
      return state;
  }
};

export default accessReducer;
