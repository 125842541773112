import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button } from "../atoms";

const baseStyle = {
  transition: "border 150ms ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const InputFileUpload = ({
  preview,
  uploadAction,
  disableRevokeObjectURL,
  ...props
}) => {
  const { t } = useTranslation(["common"]);

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      uploadAction(acceptedFiles[0]);
    },
    accept: {
      "video/mp4": [".mp4"],
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => !disableRevokeObjectURL && URL.revokeObjectURL(preview);
  }, [preview]);

  // const files = acceptedFiles.map((file, errors) => {
  //   console.log("file", file);
  //   console.log("errors", errors);

  //   return (
  //     <li key={file.path}>
  //       {file.path} - {file.size} bytes
  //     </li>
  //   );
  // });

  const dropzoneStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  // console.log("preview inputvideoupload:", preview);

  return (
    <div className="mb-2">
      {/* <div>Logo</div> */}
      <div className="flex flex-col items-center w-full gap-8">
        <div
          {...getRootProps({
            className:
              "relative w-full border-2 border-transparent bg-gray-200 overflow-hidden rounded-lg hover:cursor-pointer after:content:[''] after:w-full after:block after:pt-[100%]",
            style: dropzoneStyle,
          })}
        >
          <input {...getInputProps()} />
          {preview ? (
            <div className="absolute w-full h-full flex items-center">
              <video className="w-full object-cover" alt="">
                <source src={preview} type="video/mp4" />
              </video>
            </div>
          ) : (
            <p className="w-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              Drag 'n' drop file here
              <br /> (.mp4 only)
            </p>
          )}
        </div>
        <div className="space-y-4">
          {preview ? (
            <Button
              type="tertiary"
              twWidth="w-48"
              onClick={() => {
                // console.log("click");
                URL.revokeObjectURL(preview);
                uploadAction("");
              }}
            >
              {t("buttons.remove")}
            </Button>
          ) : (
            <Button type="primary" twWidth="w-48" onClick={open}>
              {t("buttons.upload")}
            </Button>
          )}
        </div>
        {_.isString(props.error) && (
          <div className="form-error text-red-500 text-sm -mt-6">
            {props.error}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFileUpload;
