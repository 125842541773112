import React from "react";

const SplitLayout = (props) => {
  return (
    <div className={`flex ${props.classes}`}>
      <div
        className={`${
          props.leftContentClasses ? props.leftContentClasses : "w-2/3 pr-8"
        } `}
      >
        {props.leftContent}
      </div>
      <div
        className={`${
          props.rightContentClasses ? props.rightContentClasses : "w-1/3 pl-8"
        } ${
          props.rightContent &&
          (props.noBorder ? null : "border-l-2 border-gray-200")
        }`}
      >
        {props.rightContent}
      </div>
    </div>
  );
};

export default SplitLayout;
