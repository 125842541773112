/* ===== APP ===== */
export const LOADER = "LOADER"
/* ===== END APP ===== */

/* ===== AUTH ===== */
export const REQUEST_LOGIN = "REQUEST_LOGIN"
export const REQUEST_LOGOUT = "REQUEST_LOGOUT"
export const AUTHENTICATE_2FA = "AUTHENTICATE_2FA"
export const VERIFY_2FA = "VERIFY_2FA"
export const ENABLE_2FA = "ENABLE_2FA"
export const SET_LOGIN_PIC = "SET_LOGIN_PIC"
export const DISABLE_2FA = "DISABLE_2FA"
export const IS_2FA_ENABLED_FROM_PROFILE = "IS_2FA_ENABLED_FROM_PROFILE"
export const IS_TOKEN_EXPIRED = "IS_TOKEN_EXPIRED"

/* ===== COUNTER ===== */
export const INCREMENT = "INCREMENT"
export const DECREMENT = "DECREMENT"

/* ===== ACCESSIBILITY OPTIONS ===== */
export const FONT_SIZE = "FONT_SIZE"
export const CONTRAST_MODE = "CONTRAST_MODE"
export const LANGUAGE_OPTION = "LANGUAGE_OPTION"
export const SET_ACCESS_OPTIONS = "SET_ACCESS_OPTIONS"
export const SET_ACCESS_LOGIN_PROMPT = "ENABLE_ACCESS_LOGIN_PROMPT" // Enables/Disables the prompt post-login
export const TOGGLE_ACCESS_LOGIN_PROMPT = "TOGGLE_ACCESS_OPTIONS_PROMPT" // Toggles prompt to display only after the first login

/* ===== ORGANISATION ===== */
export const LOGIN_ORGANISATION = "LOGIN_ORGANISATION"
export const LOGOUT_ORGANISATION = "LOGOUT_ORGANISATION"
export const TOGGLE_ORGANISATION_PORTAL_VIEW = "TOGGLE_ORGANISATION_PORTAL_VIEW"
export const UPDATE_ROLE_AND_RIGHTS = "UPDATE_ROLE_AND_RIGHTS"

/* ===== CURRENT VIDEO ===== */
// export const VIEW_CURRENT_VIDEO = "VIEW_CURRENT_VIDEO"
export const SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO"
export const SET_CURRENT_VIDEO_FIELD = "SET_CURRENT_VIDEO_FIELD"
export const SET_AUTO_EDIT_MODE = "SET_AUTO_EDIT_MODE"

/* ===== LIVE FEED ===== */
export const UPDATE_PRIORITY_COUNT = "UPDATE_PRIORITY_COUNT"