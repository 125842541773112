import React from "react";
import ReactDOM from "react-dom/client";
import { UserbackProvider } from "@userback/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./dist/main.css";
import "./utils/Localisation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <UserbackProvider token="38930|77217|CS7JAykCxHVn81E57UIqC0MJp">
    <App />
  </UserbackProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
