import React from "react";

const SubHeader = (props) => {
  return (
    <div className={`min-h-[5rem] w-full ${props.classes}`} >
      {props.children}
    </div>
  );
};

export default SubHeader;
