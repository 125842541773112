import { useEffect, useRef, useState } from "react";
import { iconX_Navy } from "../../../components/atoms/Icons";
import { StateView } from "../../../utils/StateView";

const VideoProgressBar = ({
  values,
  progressRate,
  showModal,
  cancelRequest,
}) => {
  const overlayModalEl = useRef(); // To ref element for '_useOnClickOutside' to close when clicked out of the referenced element

  const [previewThumbnail, setPreviewThumbnail] = useState("");

  // console.log('values progressbar:', values);

  useEffect(() => {
    if (values.file_preview) {
      setPreviewThumbnail(values.file_preview);
    }
  }, [previewThumbnail]);

  return (
    <div
      className={`fixed z-10 inset-0 w-full h-full bg-mirage transform transition-all duration-150 ${
        showModal
          ? "bg-opacity-75 pointer-events-auto"
          : "bg-opacity-0 pointer-events-none"
      }
    `}
    >
      <div
        className={`bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md w-3/4 sm:w-2/3 xl:w-1/2 max-w-[1000px] h-24
      ${showModal ? "block" : "hidden"}`}
        ref={overlayModalEl}
      >
        <div className="absolute inset-0">
          <div className="rounded-md overflow-hidden h-full">
            <div className={`relative overflow-y-auto h-full p-0`}>
              <div className="flex flex-col h-full">
                <div className="flex-1 flex items-center justify-between p-2">
                  <div className="flex-1 flex items-center gap-6">
                    {/**
                      MEDIA FILE CAN ONLY BE PREVIEWED ONCE IT IS LOADED,
                      THIS IS MORE APPARENT FOR VIDEO FILES
                    */}
                    {previewThumbnail && (
                      <div className="w-1/4 min-h-[40px] max-h-[80px] relative after:[content:''] after:block after:pt-[33.33%]">
                        <video
                          id="progressbar-preview"
                          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-full w-auto object-contain rounded-md"
                          alt=""
                        >
                          <source src={previewThumbnail} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    <p className="font-bold">Uploading...</p>

                    <p className="font-bold">
                      {values.title ? values.title : values.file.path}
                    </p>
                  </div>
                  <div className="flex items-center gap-10">
                    {/* <img
                      src={iconX_Navy}
                      alt=""
                      className="w-6 opacity-25 hover:cursor-pointer"
                      onClick={cancelRequest}
                    /> */}
                  </div>
                </div>
                <progress
                  className=""
                  value={progressRate}
                  max="100"
                ></progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoProgressBar;
