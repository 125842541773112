import React from "react";
import _ from "lodash";
import { FieldWrapper } from ".";

const InputCheckbox = React.forwardRef((props, ref) => {
  // fixes error of custom prop needs to be converted to string when spread props
  // const { isReadonly, ...filteredProps } = props;
  const {...filteredProps} = props;

  return (
    <FieldWrapper
      label={props.label}
      error={_.isString(props.error) && props.error}
      type={props.type}
    >
      <input
        className=""
        type={props.type}
        ref={ref}
        {...filteredProps}
      ></input>
    </FieldWrapper>
  );
});

export default InputCheckbox;
