import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    // This removes default behaviour of fetching file 'translations.json'
    ns: [
      "common",
      "account",
      "camera",
      "header",
      "organisation",
      "permissions",
      "priority",
      "table",
      "user",
      "video",
    ],
    load: "languageOnly",
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
