import * as actionTypes from "../actions/action_types";
import produce from "immer";

const INITIAL_STATE = {
  priority_count: 0,
};

const livefeedReducer = (state = INITIAL_STATE, action) => {
  // console.log('action: ', action)
  switch (action.type) {
    case actionTypes.UPDATE_PRIORITY_COUNT:
      return produce(state, (draft) => {
        draft.priority_count = action.payload;
      });
    default:
      return state;
  }
};

export default livefeedReducer;
