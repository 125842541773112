import React, { useState } from "react";
import _ from "lodash";

const FieldWrapperInline = (props) => {
  return (
    <div
      className={`relative ${props.label && "min-h-[3rem]"}`}
    >
      {props.label && (
        <label className={`text-white block opacity-50 text-sm`}>
          {props.label}
        </label>
      )}

      {/* {props.type === "text" ? (
        <div className="flex items-center">{props.children}</div>
      ) : (
        <div className="">{props.children}</div>
      )} */}
      {props.children}

      {_.isString(props.error) && (
        <div className="form-error text-red-500 text-sm mt-2">
          {props.error}
        </div>
      )}
    </div>
  );
};

export default FieldWrapperInline;
