import React from "react";
import FieldWrapper from "./FieldWrapper";

const InputRadios = (props) => {
  return (
    <div>
      {props.options.map((option) => (
        <FieldWrapper
          type={props.type}
          twCss={props.twCss}
          optionLabel={option.label}
          id={`${option.id}-${option.value}`}
          key={option.id}
        >
          <input
            checked={props.checked === option.value}
            type={props.type}
            name={props.name}
            onChange={props.onChange}
            id={`${option.id}-${option.value}`}
            value={option.value}
          />
        </FieldWrapper>
      ))}
    </div>
  );
};

export default InputRadios;
