import _ from "lodash";
import React, { useEffect, useState } from "react";
import { updatePriorityCount } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import api from "../../utils/api";

const NavLinks = ({
  menu,
  type,
  orgPortalView,
  orgId,
  rdxPriorityTotal,
  ...props
}) => {
  const { t } = useTranslation("common");
  let location = useLocation();
  // console.log("orgPortalView:", orgPortalView);
  // const [priorityTotal, setPriorityTotal] = useState(0);

  useEffect(() => {
    if (orgId && orgPortalView === "user") {
      _fetchPriorityCount(orgId);
    }
  }, [orgPortalView, orgId]);

  const _fetchPriorityCount = (rdxOrgId) => {
    api
      .post(`organisation/${rdxOrgId}/video/totalPriorities`, {})
      .then((response) => {
        const { total } = response.data.result;
        // console.log("response:", total);

        // console.log('From Sidebar - priority count!');
        // setPriorityTotal(total);
        props.updatePriorityCount(total);
      })
      .catch((error) => {
        console.log("error :", error);
      });
  };

  const currentPath = location.pathname;

  // get slug of current path (e.g. /checkouts/addedit -> /checkouts)
  // if current path is default path, return default path
  const slug =
    currentPath === "/"
      ? currentPath
      : currentPath.match(/^(?:[^/]*\/){1}[^/]+/gm);

  // get slug of current path (e.g. /org/videos/edit/3 -> /org/videos)
  // if current path is default path, return default path
  const slugOrg =
    currentPath === "/org/"
      ? currentPath
      : currentPath.match(/^(?:[^/]*\/){2}[^/]+/gm);

  // if slug matches current path, set active state
  const _isActive = (slug, urls) => {
    let isActive = false;

    const checkIfActive = (url) => {
      /**
       * Different slug is used on whether in the Org portal or not
       * Whether "/" or ["/XXX"] for slug, slug gives a boolean when compared to the url argument
       */
      if (orgId) {
        return (
          slugOrg !== null &&
          (_.isArray(slugOrg) ? slugOrg[0] === url : slugOrg === url)
        );
      } else {
        return slug !== null && slug[0] === url;
      }
    };

    if (_.isArray(urls)) {
      urls.map((url) => {
        if (checkIfActive(url)) {
          isActive = true;
        }
      });
    } else {
      isActive = checkIfActive(urls);
    }

    return isActive;
  };

  return (
    menu &&
    menu.map((item, i) => {
      const hoverBorderL =
        type === "admin" &&
        `hover:border-l-4 hover:border-pictonblue ${
          _isActive(slug, item.url) && "border-l-4 border-pictonblue"
        }`;
      return (
        <Link
          key={i}
          className={`pl-3 pr-6 py-1 min-h-[56px] flex items-center justify-start gap-3 hover:bg-primary-active ${
            _isActive(slug, item.url) && "bg-primary-active"
          } ${hoverBorderL} transition-all cursor-pointer`}
          to={item.url}
        >
          <div className="flex items-center">
            <div className="w-12">
              <img src={item.icon} alt="" className="w-full" />
            </div>
            <span className="pl-2">{t(`navigation.${item.value}`)}</span>
          </div>

          {rdxPriorityTotal > 0 && item.name === "Priority" && (
            <div className="bg-tomato font-semibold w-8 h-8 rounded-full border-0 flex justify-center items-center">
              {rdxPriorityTotal}
            </div>
          )}
        </Link>
      );
    })
  );
};

const mapStateToProps = (state) => {
  return {
    orgPortalView: state.Organisation.org_portal_view,
    orgId: state.Organisation.org_login.id,
    rdxPriorityTotal: state.Livefeed.priority_count,
  };
};

const mapDispatchToProps = {
  updatePriorityCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavLinks);
