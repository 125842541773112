import App from "./app_reducer";
import Auth from "./auth_reducer";
// import Counter from "./counter_reducer"
import Access from "./access_reducer";
import Organisation from "./organisation_reducer";
import Currentvideo from "./currentvideo_reducer";
import Livefeed from "./livefeed_reducer";

const reducers = {
  App,
  Auth,
  // Counter,
  Access,
  Organisation,
  Currentvideo,
  Livefeed
};

export default reducers;
