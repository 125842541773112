import React, { useEffect, useState } from "react";
// import api from "../../../utils/api";
// import { StateView } from "../../../utils/StateView";
import { Loader3 } from "..";

const Thumbnail = ({ src, videoId }) => {
  const [imageError, setImageError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  /**
   * If the image fires an error, it should fetch the videoId url to check for the thumbnail again and see if there is an error.
   * It should keep fetching the thumbnail for a maximum of 3 times
   * While it is fetching, it should show the loader
   * If the thumbnail is visible then show, if not show a 'No thumbnail' placeholder
   */

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onerror = () => {
      if (retryCount < 3) {
        setTimeout(() => {
          // console.log(`retryCount ${videoId}:`, retryCount);
          setRetryCount(retryCount + 1);
        }, 3000);
      } else {
        setImageError(true);
      }
    };
  }, [src, retryCount]);

  return (
    <>
      {/* <StateView values={imageError} title="imageError" />
      <StateView values={retryCount} title="retryCount" /> */}
      <div className="w-full min-w-[120px] max-w-[180px] relative after:[content:''] after:block after:pt-[40%] rounded-md">
        {imageError ? (
          <div className="absolute top-0 left-0 w-full h-full bg-gray-200 flex justify-center items-center">
            <i className="text-xs">No thumbnail</i>
          </div>
        ) : retryCount > 0 && retryCount !== 3 ? (
          <div className="absolute top-0 left-0 w-full h-full bg-gray-200 flex justify-center items-center">
            <Loader3 />
          </div>
        ) : (
          <img
            src={src}
            alt=""
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full object-cover"
            data-tag="allowRowEvents"
          />
        )}
      </div>
    </>
  );
};

export default Thumbnail;
