import * as actionTypes from "./action_types";

export const loginOrganisation = (data) => {
  return {
    type: actionTypes.LOGIN_ORGANISATION,
    payload: data,
  };
};

export const logoutOrganisation = () => {
  return {
    type: actionTypes.LOGOUT_ORGANISATION,
  };
};

export const toggleOrganisationPortalView = (input) => {
  return {
    type: actionTypes.TOGGLE_ORGANISATION_PORTAL_VIEW,
    payload: input,
  };
};

export const updateRoleAndRights = (input) => {
  return {
    type: actionTypes.UPDATE_ROLE_AND_RIGHTS,
    payload: input,
  };
};
