import React, { useEffect, useState } from "react";
// Components
import Container from "../atoms/Container";
import { Header, Sidebar } from "../molecules";
// Utilities
import { StateView } from "../../utils/StateView";
import { _detectPageRefresh } from "../../utils/helper";

const LayoutWrapper = ({
  userType,
  access_token,
  token2FA,
  children,
  isEnabled2FA,
  isVerified2FA,
  history,
}) => {
  const [currentPathname, setCurrentPathname] = useState("");

  useEffect(() => {
    // Listen in redux history for every route change (since it's SPA)
    const unlisten = history.listen((location) => {
      setCurrentPathname(location.pathname);
    });

    /**
     * Detecting a page change like a opening a new tab or anything not tracked by Redux history
     */
    const windowLocationPage = window.location;

    if (windowLocationPage) {
      // console.log("Page change");
      setCurrentPathname(windowLocationPage.pathname);
    } else {
      unlisten();
    }

    return function cleanup() {
      unlisten();
    };
  }, [history, currentPathname]);

  // const orgVideosPage = pathname.includes("/org/videos");
  const videoHighlightEditPage = currentPathname.includes("/org/videos/edit");
  const videoHighlightPlayPage = currentPathname.includes("/org/videos/play");

  const substringPaths = [
    "/org/videos/edit",
    "/org/videos/play",
    "/org/shared/play",
    "/org/priority/play",
    "/org/recent/play",
    "/org/starred/play",
  ];

  // Monitors the current pathname to check whether it matches the substring paths
  const pathsWithNoSidebar = substringPaths.some((element) => {
    return currentPathname.includes(element);
  });

  // console.log('pathsWithNoSidebar:', pathsWithNoSidebar);

  // console.log("orgVideosPage:", orgVideosPage);
  // console.log("videoHighlightEditPage:", videoHighlightEditPage);
  // console.log("videoHighlightPlayPage:", videoHighlightPlayPage);

  useEffect(() => {
    // Detects a page reload since history.listen only triggers route change
    if (_detectPageRefresh()[0] === "reload") {
      // console.log("Reload detected");
      setCurrentPathname(history.location.pathname);
    }
  }, [history]);

  if (userType && access_token) {
    // console.log("LayoutWrapper - logged in");

    // For 2FA Authentication disabled
    if (!isEnabled2FA && !isVerified2FA) {
      // console.log("LayoutWrapper(logged in) - 2fa is turned off");
      return (
        <div className="absolute inset-0">
          <Header />
          <div className="absolute top-20 left-0 bottom-0 right-0">
            <div className="flex h-full">
              {/* Video Highlights page does not show Sidebar (TEST ON DEPLOYMENT IF TERNARY IS ACCURATE) */}
              {/* {videoHighlightEditPage ? null : videoHighlightPlayPage ? null : (
                <Sidebar />
              )} */}
              {pathsWithNoSidebar ? null : <Sidebar />}
              <div className="relative flex-1 overflow-y-auto min-h-full bg-white">
                {/* <StateView values={currentPathname} title="currentPathname" /> */}
                {children}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // For 2FA Authentication Enabled
      if (token2FA) {
        // console.log("LayoutWrapper(logged in) - Got 2fa token");
        return (
          <div className="absolute inset-0">
            <Header />
            <div className="absolute top-20 left-0 bottom-0 right-0">
              <div className="flex h-full">
                {/* Video Highlights page does not show Sidebar (TEST ON DEPLOYMENT IF TERNARY IS ACCURATE) */}
                {pathsWithNoSidebar ? null : <Sidebar />}
                <div className="relative flex-1 overflow-y-auto min-h-full bg-white">
                  {children}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        // console.log("LayoutWrapper(logged in) - No 2fa token");
        return children;
      }
    }
  } else {
    // console.log("LayoutWrapper - not logged in");
    return <Container type="centred">{children}</Container>;
  }
};

export default LayoutWrapper;
