import React, { useState } from "react";
import _ from "lodash";
import { FieldWrapper } from ".";
import { iconEyeview_Navy } from "../atoms/Icons";
// import { StateView } from "../../utils/StateView";

const InputGroup = React.forwardRef((props, ref) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // fixes error of custom prop needs to be converted to string when spread props
  const { isReadonly, isDisabled, ...filteredProps } = props;

  const inputClasses = `block w-full px-4 py-5 rounded-md max-w-[450px] border border-gray-200 focus-visible:outline-pictonblue ${
    isDisabled && "opacity-50"
  }`;

  // console.log(filteredProps);
  return (
    <React.Fragment>
      {/* <StateView values={isPasswordVisible} /> */}
      <FieldWrapper
        label={props.label}
        error={_.isString(props.error) && props.error}
        alignment={props.alignment}
      >
        <div className="relative">
          <input
            type={isPasswordVisible ? "text" : "password"}
            disabled={isDisabled}
            className={inputClasses}
            ref={ref}
            {...filteredProps}
            placeholder={props.placeholder}
          />
          <img
            src={iconEyeview_Navy}
            alt=""
            className="absolute right-2 top-1/2 -translate-y-1/2 w-10 hover:opacity-50 hover:cursor-pointer"
            onMouseDown={() => setIsPasswordVisible(true)}
            onMouseUp={() => setIsPasswordVisible(false)}
          />
        </div>
      </FieldWrapper>
    </React.Fragment>
  );
});

export default InputGroup;
