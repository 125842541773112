import i18next from "i18next";

export const langOptions = [
  {
    id: 1,
    label: "Dutch",
    value: "nl",
  },
  {
    id: 2,
    label: "English",
    value: "en",
  },
];

export const fontSizeOptions = [
  {
    id: 1,
    label: "100%",
    value: 100,
  },
  {
    id: 2,
    label: "125%",
    value: 125,
  },
  {
    id: 3,
    label: "150%",
    value: 150,
  },
];

export const contrastOptions = [
  {
    id: 1,
    label: "Light mode",
    value: "light",
  },
  {
    id: 2,
    label: "Dark mode",
    value: "dark",
  },
];

export const roleOptions = [
  {
    id: 1,
    label: "Interviewee",
    value: "interviewee",
    rights: [
      "VIEW",
      "SHARE",
      "META_VIEW",
      "DELETE",
      "DOWNLOAD",
      "HIDE",
      "NOTES",
    ],
  },
  {
    id: 2,
    label: "Interviewer",
    value: "interviewer",
    rights: [
      "RECORD",
      "VIEW",
      "SHARE",
      "META_VIEW",
      "META_EDIT",
      "DELETE",
      "UPLOAD",
      "DOWNLOAD",
      "TRANSFER",
      "HIDE",
      "RESEARCH",
      "EDIT",
      "MARK",
      "NOTES",
    ],
  },
  {
    id: 3,
    label: "Reviewer",
    value: "reviewer",
    rights: ["VIEW", "META_VIEW", "HIDE", "MARK", "NOTES"],
  },
  {
    id: 4,
    label: "Account admin",
    value: "account_admin",
    rights: ["META_VIEW", "CAMERA", "ACCOUNT", "NOTES"],
  },
  {
    id: 5,
    label: "Video admin",
    value: "video_admin",
    rights: [
      "META_VIEW",
      "META_EDIT",
      "DELETE",
      "UPLOAD",
      "DOWNLOAD",
      "TRANSFER",
      "CAMERA",
      "RESEARCH",
      "MOVE",
      "NOTES",
    ],
  },
  {
    id: 6,
    label: "Super admin",
    value: "super_admin",
    rights: ["META_VIEW", "CAMERA", "ACCOUNT"],
  },
];

export const rightsOptions = [
  {
    label: "Record",
    value: "RECORD",
  },
  {
    label: "View",
    value: "VIEW",
  },
  {
    label: "Share",
    value: "SHARE",
  },
  {
    label: "Meta view",
    value: "META_VIEW",
  },
  {
    label: "Meta edit",
    value: "META_EDIT",
  },
  {
    label: "Delete",
    value: "DELETE",
  },
  {
    label: "Upload",
    value: "UPLOAD",
  },
  {
    label: "Download",
    value: "DOWNLOAD",
  },
  {
    label: "Transfer",
    value: "TRANSFER",
  },
  {
    label: "Camera",
    value: "CAMERA",
  },
  {
    label: "Account",
    value: "ACCOUNT",
  },
  {
    label: "Hide",
    value: "HIDE",
  },
  {
    label: "Research",
    value: "RESEARCH",
  },
  {
    label: "Move",
    value: "MOVE",
  },
  {
    label: "Edit",
    value: "EDIT",
  },
  {
    label: "Mark",
    value: "MARK",
  },
  {
    label: "Notes",
    value: "NOTES",
  },
];

export const userVideosOptions = [
  {
    id: 1,
    label: "View",
    value: "view",
  },
  {
    id: 2,
    label: "Edit",
    value: "edit",
  },
  {
    id: 3,
    label: "Share",
    value: "share",
    disable: false,
  },
  {
    id: 4,
    label: "Delete",
    value: "delete",
  },
  {
    id: 5,
    label: "Transfer",
    value: "transfer",
  },
  {
    id: 6,
    label: "Priority",
    value: "priority",
    disable: true,
  },
  // {
  //   id: 6,
  //   label: "Move to",
  //   value: "move-to",
  // },
];

export const adminVideosOptions = [
  // {
  //   id: 1,
  //   label: "Edit",
  //   value: "edit",
  // },
  // {
  //   id: 2,
  //   label: "Priority",
  //   value: "priority",
  // },
  // {
  //   id: 3,
  //   label: "Share",
  //   value: "share",
  // },
  // {
  //   id: 4,
  //   label: "Lock",
  //   value: "lock",
  // },
  // {
  //   id: 6,
  //   label: "Move to",
  //   value: "move-to",
  // },
  // {
  //   id: 7,
  //   label: "Delete",
  //   value: "delete",
  // },
];

export const superAdminUsersOptions = [
  {
    id: 1,
    // label: i18next.t("common:options.edit"),
    label: "Edit",
    value: "edit",
    disable: false,
  },
  {
    id: 2,
    // label: i18next.t("common:options.reset_password"),
    label: "Reset password",
    value: "reset_password",
    disable: true,
  },
  {
    id: 3,
    // label: i18next.t("common:options.suspend"),
    label: "Suspend",
    value: "suspend",
    disable: true,
  },
  {
    id: 4,
    // label: i18next.t("common:options.delete_user"),
    label: "Delete user",
    value: "delete_user",
    disable: true,
  },
  // {
  //   id: 6,
  //   label: "Move to",
  //   value: "move-to",
  // },
  // {
  //   id: 7,
  //   label: "Delete",
  //   value: "delete",
  // },
];

// export const priorityTypeOptions = [
//   {
//     id: 1,
//     label: "Share",
//     value: "share",
//   },
//   {
//     id: 2,
//     label: "Share",
//     value: "share",
//   },
//   {
//     id: 3,
//     label: "Share",
//     value: "share",
//   },
// ]

export const orgPortalOptions = [
  {
    id: 1,
    label: "Withdraw access to this organisation",
    value: "withdraw",
  },
];

export const auth2faOptions = [
  {
    id: 1,
    label: "Authenticator",
    value: "auth",
  },
  {
    id: 2,
    label: "Email",
    value: "email",
  },
];
