import React from "react"

const Loader2 = () => {
  return (
    <div className="flex justify-center items-center h-48">
      <div className="loader-2" />
    </div>
  )
}

export default Loader2
