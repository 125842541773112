import _ from "lodash";
import { useEffect } from "react";
import { store } from "../redux/store";
// import { useTranslation } from "react-i18next";
import i18n from "i18next";

// const check = {
//   fullname: 'Sila isi nama penuh anda',
//   rider_id: 'Sila isi ID rider anda',
//   email: 'Sila isi e-mel anda',
// };

// const validate = _validateForm(check, formData);

export const _validateForm = (check, formData) => {
  return Object.keys(check).reduce((obj, key) => {
    // console.log("obj: ", obj);
    // console.log("formData: ", formData);
    // console.log("key: ", key);

    if (
      formData[key] === undefined ||
      formData[key] === null ||
      formData[key] === "" ||
      formData[key].length === 0 ||
      formData[key][0] === ""
    ) {
      obj[key] = check[key]; // Adds a new key-value pair in the empty object (initialValue) based on corresponding keys
    } else if (key === "email") {
      const email = formData[key];

      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (regex.test(String(email).toLowerCase()) === false) {
        obj[key] = "Email is invalid";
      }
    } else if (key === "agree_tnc") {
      const agreeTnc = formData[key];

      if (agreeTnc === false) {
        obj[key] = check[key] ? check[key] : "This needs to be ticked";
      }
    }

    return obj;
  }, {});
};

export const _findOption = (list, id) => {
  return list.find((option) => {
    return _.isObject(option.value)
      ? _objectIsEqual(option.value, id)
      : String(option.value) === String(id);
  });
};

export const _difference = (object, base) => {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] =
        _.isObject(value) && _.isObject(base[key])
          ? _.difference(value, base[key])
          : value;
    }
  });
};

export const _objectIsEqual = (object, base) => {
  return _.isEmpty(_difference(object, base));
};

// Result - yyyy-mm-dd
export const _convertDate = (date) => {
  if (date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  }

  return date;
};

export const _buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      _buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};

// Extracts values from the array[0] for forms
export const _displayFormErrors = (errors) => {
  return Object.keys(errors).reduce((obj, key) => {
    obj[key] = errors[key][0];
    return obj;
  }, {});
};

export const _useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because the passed-in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

/**
 * PerformanceNavigationTiming
 *  - The PerformanceNavigationTiming interface provides methods and properties
 *    to store and retrieve metrics regarding the browser's document navigation events.
 *    For example, this interface can be used to determine how much time it takes to
 *    load or unload a document.
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
 */

// Debug PerformanceNavigationTiming
export const _printNavTimingData = () => {
  // Use getEntriesByType() to just get the "navigation" events
  performance.getEntriesByType("navigation").forEach((p, i) => {
    console.log(`= Navigation entry[${i}]`);

    // DOM Properties
    console.log(
      `DOM content loaded = ${
        p.domContentLoadedEventEnd - p.domContentLoadedEventStart
      }`
    );
    console.log(`DOM complete = ${p.domComplete}`);
    console.log(`DOM interactive = ${p.domInteractive}`);

    // Document load and unload time
    console.log(`document load = ${p.loadEventEnd - p.loadEventStart}`);
    console.log(`document unload = ${p.unloadEventEnd - p.unloadEventStart}`);

    // Other properties
    console.log(`type = ${p.type}`);
    console.log(`redirectCount = ${p.redirectCount}`);
  });
};

// Detect page refresh
export const _detectPageRefresh = () => {
  const getReloadType = performance
    .getEntriesByType("navigation")
    .map((p, i) => {
      return p.type;
    });
  return getReloadType;
};

// Checks whether Text selection is within a specific DOM element
export const _elementContainsSelection = (el) => {
  var sel = window.getSelection();
  if (sel.rangeCount > 0) {
    for (var i = 0; i < sel.rangeCount; ++i) {
      if (!el.contains(sel.getRangeAt(i).commonAncestorContainer)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

/**
 * 'requiredRight' needs to be defined from the frontend
 * - It can either be "RECORD","SHARE","DELETE","UPLOAD",
 *   "DOWNLOAD","TRANSFER","EDIT" or "ADMIN"
 * - It will check in the Redux store if the user is granted this
 *   right from an array of rights when logged into Organisation portal
 */
export const _permissionsRequired = (requiredRight) => {
  const listOfRights =
    store.getState().Organisation.org_login.role.rights || [];
  // console.log("listOfRights:", listOfRights);
  return listOfRights.includes(requiredRight);
};

/**
 * Filters i.e. show/hide, options available for the user
 * to execute both on the user & admin portal
 *
 * grantedRights - Array of rights from redux
 */
export const _userGrantedOptions = (optionsList, grantedRights) => {
  // 'priority' option is an exception from list
  const filterOptions = optionsList.filter(
    (option) =>
      grantedRights.includes(option.value.toUpperCase()) ||
      option.value === "priority" ||
      option.value === "view"
  );

  const translateOptions = filterOptions.map((right) => {
    const { label, ...otherProps } = right;
    return {
      ...otherProps,
      label: i18n.t(`common:options.${label.toLowerCase()}`, { ns: "common" }),
    };
  });

  return translateOptions;
};

export const _dateSortingFromRecordingDate = (rowA, rowB) => {
  // Only works if the table row has the field 'recorded_at'
  const rowAgetTime = new Date(rowA.recorded_at).getTime();
  const rowBgetTime = new Date(rowB.recorded_at).getTime();

  return rowAgetTime - rowBgetTime;
};
