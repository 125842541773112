import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

const AuthRoute = ({ component: Component, Auth, path, ...rest }) => {
  const { access_token } = Auth;

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        // If :token from params matches with access_token from redux store, then show the component
        if (props.match.params.token === access_token) {
          return <Component {...props} />;
        }
        // If not, return to homepage
        return props.history.push('/');
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    Auth: state.Auth,
  };
};

export default connect(mapStateToProps, null)(AuthRoute);
