import React from "react";
import _ from "lodash";

const FieldWrapper = (props) => {
  const checkedCss = props.checked
    ? "border-2 border-pictonblue"
    : "border border-gray-200";

  return props.alignment === "horizontal" ? (
    <React.Fragment>
      <div
        className={`flex items-center ${
          !props.customToggleSwitch && "justify-between"
        } mb-2`}
      >
        <div
          className={` ${
            props.customToggleSwitch
              ? "w-8/12 max-w-[350px]"
              : "w-5/12 max-w-[350px]"
          }`}
        >
          {" "}
          {props.label}
        </div>
        <div
          className={`${
            props.customToggleSwitch
              ? "w-4/12 flex justify-end max-w-[100px]"
              : "w-7/12 flex-1 "
          } `}
        >
          {props.children}
          {_.isString(props.error) && (
            <div className="form-error text-red-500 text-sm mt-2">
              {props.error}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  ) : (
    <div
      className={`${props.twCss && `${props.twCss}`} mb-2 ${
        !!props.wrapperCss && props.wrapperCss
      }`}
    >
      {props.type === "checkbox" ? (
        <label title={props.title} className="checkbox-container">
          {props.label}
          {props.children}
          <span className="checkmark"></span>
        </label>
      ) : props.type === "radio" && props.custom ? (
        <label htmlFor={props.id}>
          <div className={`flex flex-col items-center gap-2`}>
            <div
              className={`w-full ${checkedCss} rounded-md transform transition-colors duration-150 after:content-[''] after:block after:pt-[100%] relative hover:cursor-pointer`}
            >
              <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 font-bold">
                {props.optionLabel}
              </span>
            </div>
            {props.children}
          </div>
        </label>
      ) : props.type === "radio" ? (
        <div className="flex items-center gap-2">
          {props.children}
          <label className="hover:cursor-pointer" htmlFor={props.id}>
            {props.optionLabel}
          </label>
        </div>
      ) : (
        <div
          className={`relative rounded-md 
              ${props.isError ? "border-red-500" : "border-primary"} 
              ${!!props.css && props.css}`}
        >
          <label className={`form-label text-primary block px-1 mb-1`}>
            {props.label}
          </label>
          {props.children}
        </div>
      )}

      {_.isString(props.error) && (
        <div className="form-error text-red-500 text-sm mt-2">
          {props.error}
        </div>
      )}
    </div>
  );
};

export default FieldWrapper;
