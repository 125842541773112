import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, Auth, path, ...rest }) => {
  const { isVerified2FA, isEnabled2FA, token2FA, access_token } = Auth;

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (isEnabled2FA) {
          // console.log("2fa is enabled");

          // ATM, token2fa only provided after OTP and not through normal login
          if (isVerified2FA && token2FA) {
            // console.log("Verified & have token2fa");

            return <Component {...props} />;
          } else {
            // console.log(
            //   "Neither Verfied nor have token2fa; Or has 1 but none for the other"
            // );

            return (
              <Redirect
                to={{
                  pathname: `/verify2fa/${access_token}`,
                  state: {
                    prevLocation: path,
                    error: "Account needs to be verified for 2FA",
                  },
                }}
              />
            );
          }
        } else {
          // console.log("2fa is disabled");

          return <Component {...props} />;
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    Auth: state.Auth,
  };
};

export default connect(mapStateToProps, null)(ProtectedRoute);
