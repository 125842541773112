import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import { FieldWrapper } from ".";

import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const InputDate = React.forwardRef((props, ref) => {
  // const [startDate, setStartDate] = useState(new Date());
  const inputClasses = `block w-full px-4 py-5 rounded-md max-w-[450px] border border-gray-200 focus-visible:outline-pictonblue `;

  const { todayAndFutureOnly, todayAndPastOnly, ...filteredProps } = props;

  return (
    <FieldWrapper
      label={props.label}
      error={_.isString(props.error) && props.error}
      alignment={props.alignment}
    >
      <DatePicker
        className={inputClasses}
        ref={ref}
        dateFormat="dd-MM-yyyy"
        selected={props.value}
        placeholderText={props.placeholder}
        excludeDateIntervals={
          todayAndFutureOnly
            ? [
                {
                  start: dayjs().subtract(5, "year").$d,
                  end: dayjs().subtract(1, "day").$d,
                },
              ]
            : todayAndPastOnly
            ? [
                {
                  start: dayjs().$d,
                  end: dayjs().add(5, "year").$d,
                },
              ]
            : null
        }
        {...filteredProps}
      />
    </FieldWrapper>
  );
});

export default InputDate;
