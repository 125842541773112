import React from "react";
import { useTranslation } from "react-i18next";

const NoRecordsMessage = () => {
  const { t } = useTranslation("table");
  return (
    <div className="absolute inset-0 flex justify-center items-center">
      {t("no_records")}
    </div>
  );
};

export default NoRecordsMessage;
