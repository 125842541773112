import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { enable2fa, toggle2faIsEnabledFromProfile } from "../../redux/actions";
// Components
import { Button, OverlayModalv2 } from "../../components/atoms";
// Utilities
// import { StateView } from "../../../utils/StateView";
import {
  _convertDate,
  _displayFormErrors,
  _validateForm,
} from "../../utils/helper";

const Content = () => {
  return (
    <>
      <div className="mb-3">
        <h1>Algemene Voorwaarden</h1>
        <p>Versie: 1.0</p>
        <p>Datum: 1 maart 2023</p>
      </div>

      <div className="mb-3">
        <h3>Artikel 1 - Definities</h3>
        <p>In deze algemene voorwaarden wordt verstaan onder:</p>
        <ol>
          <li>
            Haerst: als gedefinieerd in artikel 2 van deze algemene voorwaarden;
          </li>
          <li>
            Wederpartij: de partij die met Haerst een overeenkomst aangaat of
            deze beoogt aan te gaan;
          </li>
          <li>Partijen: Haerst en Wederpartij gezamenlijk;</li>
          <li>
            Schriftelijk: schriftelijk, waaronder begrepen per elektronisch
            (e-mail-)bericht en enige handeling in een elektronische omgeving
            ((web)app) voor zover deze tot doel heeft een rechtshandeling te
            (kunnen) brengen;
          </li>
          <li>
            Derde(n): andere (rechts-)personen, niet zijnde (een der) Partijen;
          </li>
          <li>
            Duurovereenkomst: een overeenkomst waarbij beide partijen zich
            verplichten gedurende langere periode prestaties te leveren.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 2 - Identiteit van Haerst</h3>
        <p>Haerst B.V.</p>
        <p>Hamburgerweg 137</p>
        <p>3851 EJ Ermelo</p>
        <p>6525 EC Nijmegen</p>
        <p>Telefoonnummer: 085 - 7600422</p>
        <p>E-mail: info@haerst.eu</p>
        <p>KvK nummer: 61138010</p>
      </div>

      <div className="mb-3">
        <h3>Artikel 3 - Algemene bepalingen</h3>

        <ol>
          <li>
            Deze algemene voorwaarden zijn van toepassing op elk aanbod en alle
            (rechts)handelingen van Haerst en op elke tot stand gekomen
            overeenkomst tussen Haerst en Wederpartij.
          </li>
          <li>
            Indien de overeenkomst elektronisch wordt gesloten, kan in afwijking
            van het vorige lid en voordat de overeenkomst wordt gesloten, de
            tekst van deze algemene voorwaarden langs elektronische weg aan de
            Wederpartij ter beschikking worden gesteld op zodanige wijze dat
            deze door de Wederpartij op een eenvoudige manier kan worden
            opgeslagen op een duurzame gegevensdrager. Indien dit redelijkerwijs
            niet mogelijk is, zal voordat de overeenkomst wordt gesloten, worden
            aangegeven waar van de algemene voorwaarden langs elektronische weg
            kan worden kennisgenomen en dat zij op verzoek van de Wederpartij
            langs elektronische weg of op andere wijze kosteloos zullen worden
            toegezonden.
          </li>
          <li>
            Tenzij uitdrukkelijk anders en schriftelijk overeengekomen is de
            toepasselijkheid van andere algemene voorwaarden uitgesloten.
          </li>
          <li>
            Afwijkingen of aanvullingen op deze algemene voorwaarden zijn
            slechts geldig indien deze uitdrukkelijk Schriftelijk zijn
            overeengekomen.
          </li>
          <li>
            Indien en voor zover op grond van de redelijkheid en billijkheid of
            het onredelijk bezwarend karakter op enige bepaling van deze
            algemene voorwaarden geen beroep kan worden gedaan, komt aan de
            desbetreffende bepaling wat betreft de inhoud en strekking in elk
            geval een zoveel mogelijk overeenkomstige betekenis toe, zodat
            daarop wel een beroep kan worden gedaan.
          </li>
          <li>
            Haerst kan niet garanderen dat met de door hem uitgevoerde
            werkzaamheden immer het door Wederpartij gewenste resultaat bereikt
            wordt. Haerst spant zich in haar (SaaS-)product(en) voor wederpartij
            te allen tijde beschikbaar en toegankelijk te houden. De aanvaarde
            opdracht leidt tot een inspanningsverbintenis en niet tot een
            resultaatsverbintenis.
          </li>
          <li>
            Haerst is gerechtigd om voor de uitvoering van de overeenkomst
            Derden in te schakelen.
          </li>
          <li>
            De werking van artikel 7:404 en/of 7:407 lid 2 BW is/zijn
            uitgesloten. Dit houdt in dat Haerst niet is gehouden de opdracht
            zelf uit voeren, maar de opdracht door derden mag laten uitvoeren.
            Daarnaast houdt dit in dat, indien Haerst de verleende opdracht
            heeft ontvangen tezamen met een andere opdrachtnemer, Haerst niet
            voor het deel van die andere opdrachtgever kan worden aangesproken
            ter zake van een tekortkoming in de nakoming.
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h3>Artikel 4 - Het aanbod</h3>
        <ol>
          <li>
            Een aanbod van Haerst is vrijblijvend, tenzij schriftelijk anders is
            overeengekomen of uitdrukkelijk bij het aanbod is vermeld. Indien
            een aanbod een beperkte geldigheidsduur heeft of onder andere
            voorwaarden geschiedt, wordt dit nadrukkelijk in het aanbod vermeld.
          </li>
          <li>
            Het aanbod bevat een volledige en nauwkeurige omschrijving van de
            aangeboden diensten en/of digitale inhoud. De beschrijving is
            voldoende gedetailleerd om een goede beoordeling van het aanbod door
            de Wederpartij mogelijk te maken. Als Haerst gebruik maakt van
            afbeeldingen, zijn deze een waarheidsgetrouwe weergave van de
            aangeboden diensten en/of digitale inhoud. Kennelijke vergissingen
            of kennelijke fouten omtrent bijvoorbeeld weergegeven bedragen
            binden Haerst niet.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 5 - De overeenkomst</h3>
        <ol>
          <li>
            De overeenkomst komt tot stand op het moment van aanvaarding door de
            Wederpartij van het aanbod en het voldoen aan de daarbij gestelde
            voorwaarden, daaronder begrepen de acceptatie van deze Algemene
            Voorwaarden, de Verwerkersovereenkomst en de Privacyverklaring,
            tenzij Haerst een aanvaard vrijblijvend aanbod onverwijld herroept.
            Van onverwijlde herroeping is sprake indien Haerst het vrijblijvend
            aanbod binnen 24 uur na aanvaarding daarvan herroept. Indien deze
            Algemene Voorwaarden, de Verwerkersovereenkomst en het
            Privacyverklaring niet zijn geaccepteerd, is het aanbod niet
            aanvaard en is geen overeenkomst tot stand gekomen.
          </li>
          <li>
            Indien een bepaling van de algemene voorwaarden of een overeenkomst
            nietig blijkt te zijn of vernietigd wordt, tast dit niet de
            geldigheid van de gehele algemene voorwaarden of overeenkomst aan.
            Partijen treden in overleg teneinde een nieuwe bepaling ter
            vervanging van de nietige c.q. vernietigde bepaling overeen te
            komen, waarbij zoveel mogelijk het doel en de strekking van de
            nietige c.q. vernietigde bepaling in acht worden genomen.
          </li>
          <li>
            Haerst behoudt zich het recht voor om geen uitvoering te geven aan
            een gesloten overeenkomst, bijvoorbeeld indien zij gerede twijfel of
            informatie heeft dat Wederpartij niet aan haar (financiële)
            verplichtingen zal (kunnen) voldoen. Indien Haerst weigert zal zij
            Wederpartij binnen een redelijke termijn na het sluiten van de
            overeenkomst schriftelijk van de weigering op de hoogte stellen.
          </li>
          <li>
            Deze algemene voorwaarden zijn ook van toepassing op toekomstige,
            aanvullende en/of vervolgopdrachten.
          </li>
          <li>
            Afgesproken (op)leveringstermijnen zijn altijd indicatieve
            termijnen. De termijnen voor (op)levering zijn geen fatale
            termijnen. Overschrijding van een termijn geeft de Wederpartij dus
            geen recht op schadevergoeding.
          </li>
          <li>
            Indien de Wederpartij het aanbod langs elektronische weg heeft
            aanvaard, bevestigt Haerst onverwijld langs elektronische weg de
            ontvangst van de aanvaarding van het aanbod, onverminderd het recht
            van Haerst tot onmiddellijke herroeping van het aanbod als bedoeld
            in sub 1 van dit artikel.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 6 - Ontbinding en opzegtermijnen</h3>
        <ol>
          <li>
            Indien de Wederpartij een of meer van haar verplichtingen niet, niet
            tijdig of niet behoorlijk nakomt, in staat van faillissement wordt
            verklaard, (voorlopige) surseance en/of uitstel van betaling
            aanvraagt, overgaat tot liquidatie van haar bedrijf, alsmede wanneer
            haar vermogen geheel of gedeeltelijk in beslag wordt genomen of
            daarop beslag wordt gelegd, heeft Haerst het recht om de uitvoering
            van de overeenkomst op te schorten of om de overeenkomst van
            rechtswege en zonder voorafgaande ingebrekestelling geheel of
            gedeeltelijk door een schriftelijke verklaring te beëindigen en/of
            te ontbinden, één en ander naar haar keuze en steeds met behoud van
            enig haar toekomend recht op vergoeding van kosten, schade en
            interesten.
          </li>
          <li>
            Indien de overeenkomst eindigt op grond van overmacht, heeft Haerst
            recht op betaling van de ten tijde van de beëindiging van de
            overeenkomst reeds gewerkte uren, gedane investeringen alsmede
            abonnementskosten naar rato van de periode waarin de door Haerst
            aangeboden dienst beschikbaar was.
          </li>
          <li>
            Opzegging van een overeenkomst aangegaan voor een onbepaalde tijd
            geschiedt Schriftelijk en met inachtneming van een opzeggingstermijn
            van 1 (één) volledige kalendermaand, behoudens anders Schriftelijk
            overeengekomen. Opzegging geschiedt in alle gevallen tegen het einde
            van de maand. Dit betekent dat de opzeggingstermijn ingaat vanaf de
            eerste dag van de eerstvolgende kalendermaand. Opzegging van een
            overeenkomst aangegaan voor bepaalde tijd is niet voortijds
            opzegbaar.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3> Artikel 7 - Aansprakelijkheid</h3>
        <ol>
          <li>
            Haerst is niet aansprakelijk voor indirecte en directe schade. Niet
            uitgesloten is de aansprakelijkheid van Haerst voor schade die het
            gevolg is van opzet of bewuste roekeloosheid van Haerst.
          </li>
          <li>
            Indien Haerst toch aansprakelijk is voor directe schade zal de
            totale aansprakelijkheid van Haerst beperkt zijn tot vergoeding van
            schade tot maximaal het bedrag van de voor die overeenkomst bedongen
            honorarium (exclusief btw), tot een maximaal bedrag van € 3.000,-.
          </li>
          <li>
            De hoogte van de schadevergoeding zal nimmer meer bedragen dan het
            bedrag dat de aansprakelijkheidsverzekering uitkeert.
          </li>
          <li>
            Indien de overeenkomst een duurovereenkomst is met een looptijd van
            meer dan zes maanden, wordt het voor die overeenkomst bedongen
            honorarium gesteld op het totaal van vergoedingen (exclusief btw)
            van de afgelopen zes maanden voorafgaand aan de schadegebeurtenis.
          </li>
          <li>
            <p>Onder directe schade wordt verstaan:</p>
            <ol>
              <li>
                Redelijke kosten die Wederpartij zou moeten maken om de
                prestatie van Haerst aan de overeenkomst te laten beantwoorden;
                deze vervangende schade wordt echter niet vergoed indien de
                overeenkomst door of op vordering van Wederpartij wordt
                ontbonden;
              </li>
              <li>
                Redelijke kosten die Wederpartij heeft gemaakt voor het
                noodgedwongen langer operationeel houden van haar oude systeem
                of systemen en daarmee samenhangende voorzieningen doordat
                Haerst op een voor hem bindende uiterste leverdatum niet heeft
                geleverd verminderd met eventuele besparingen die het gevolg
                zijn van de vertraagde levering;
              </li>
              <li>
                Redelijke kosten gemaakt ter vaststelling van de oorzaak en de
                omvang van de schade voor zover de vaststelling betrekking heeft
                op schade in de zin van deze voorwaarden;
              </li>
              <li>
                Redelijke kosten gemaakt ter voorkoming of beperking van schade
                voor zover Wederpartij aantoont dat deze kosten hebben geleid
                tot beperking van schade in de zin van deze voorwaarden.
              </li>
            </ol>
          </li>
          <li>
            Wederpartij vrijwaart Haerst voor eventuele aanspraken van Derden,
            die in verband met de uitvoering van de overeenkomst schade lijden.
          </li>
          <li>
            Voorwaarde voor het ontstaan van enig recht op schadevergoeding is
            steeds dat Wederpartij de schade zo spoedig mogelijk na het ontstaan
            daarvan schriftelijk bij Haerst meldt. Iedere vordering tot
            schadevergoeding tegen Haerst vervalt door het enkele verloop van 12
            (twaalf) maanden na het ontstaan van de vordering.
          </li>
          <li>
            Haerst is niet aansprakelijk voor schade toegebracht door
            hulppersonen als bedoeld in artikel 6:76 BW.
          </li>
          <li>
            Haerst is niet aansprakelijk voor schade, van welke aard dan ook,
            doordat Haerst is uitgegaan van door Wederpartij verstrekte onjuiste
            en/of onvolledige gegevens.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 8 - Overmacht</h3>
        <ol>
          <li>
            In aanvulling op het bepaalde in artikel 6:75 BW geldt dat een
            tekortkoming van Haerst in de nakoming van enige verplichting jegens
            Wederpartij niet aan Haerst kan worden toegerekend in geval van een
            van de wil van Haerst onafhankelijke omstandigheid, waardoor de
            nakoming van haar verplichtingen jegens Wederpartij geheel of
            gedeeltelijk wordt verhinderd of waardoor de nakoming van haar
            verplichtingen in redelijkheid niet van Haerst kan worden verlangd.
            Tot die omstandigheden worden mede gerekend wanprestaties van
            toeleveranciers of andere Derden, stroomstoringen, hosting- of
            serveruitval en dergelijke.
          </li>
          <li>
            Indien zich een situatie als bedoeld in lid 1 van dit artikel
            voordoet als gevolg waarvan Haerst niet aan haar verplichtingen
            jegens de Wederpartij kan voldoen, dan worden, die verplichtingen
            opgeschort zolang Haerst niet aan haar verplichtingen kan voldoen.
            Indien de in de vorige zin bedoelde situatie 14 (veertien)
            kalenderdagen heeft geduurd, hebben beide Partijen het recht de
            overeenkomst schriftelijk geheel of gedeeltelijk te ontbinden.
            Haerst is in dat geval niet gehouden tot vergoeding van enige
            schade, ook niet als Haerst als gevolg van de overmachtstoestand
            enig voordeel geniet.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 9 - Garantie</h3>
        <ol>
          <li>
            Haerst staat ervoor in dat de producten en/of diensten voldoen aan
            de overeenkomst, de in het aanbod vermelde specificaties, aan de
            redelijke eisen van deugdelijkheid en/of bruikbaarheid en de op de
            datum van de totstandkoming van de overeenkomst bestaande wettelijke
            bepalingen en/of overheidsvoorschriften.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 10 - Honorarium/Prijzen</h3>
        <ol>
          <li>
            Alle bedragen zijn in euro's en exclusief omzetbelasting (btw) en
            andere heffingen welke van overheidswege worden opgelegd tenzij
            anders overeengekomen.
          </li>
          <li>
            Haerst behoudt zich het recht voor éénmaal per jaar een
            inflatiecorrectie toe te passen, zonder dat de Wederpartij op die
            grond het recht verkrijgt de overeenkomst te ontbinden.
          </li>
          <li>
            Haerst behoudt zich - buiten het sub 2 genoemde geval - voorts het
            recht voor haar prijzen aan te passen gedurende de overeenkomst. In
            het geval van een prijswijziging, niet zijnde een inflatiecorrectie,
            heeft de Wederpartij het recht de overeenkomst op te zeggen, met de
            opzegtermijn van 1 (een) maand.
          </li>
          <li>
            Een samengestelde prijsopgave verplicht Haerst niet tot het
            verrichten van een gedeelte van de opdracht tegen een overeenkomstig
            deel van het opgegeven bedrag.
          </li>
          <li>
            Kortingen en geoffreerde bedragen gelden niet automatisch voor
            toekomstige opdrachten.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 11 - Betaling en facturering</h3>
        <ol>
          <li>
            Voor zover niet anders is bepaald in de overeenkomst of aanvullende
            voorwaarden, dienen de door de Wederpartij verschuldigde bedragen te
            worden voldaan binnen 30 (dertig) dagen na factuurdatum.
          </li>
          <li>
            Betaling geschiedt, behoudens anders overeengekomen, door middel van
            een automatische incasso.
          </li>
          <li>
            Wederpartij heeft de plicht om onjuistheden in verstrekte of
            vermelde betaalgegevens onverwijld aan Haerst te melden.
          </li>
          <li>
            Indien Wederpartij niet tijdig aan haar betalingsverplichting(en)
            voldoet, is de Wederpartij in verzuim en dus ook over het nog
            verschuldigde bedrag de wettelijke (handels)rente verschuldigd.
            Bovendien is Haerst gerechtigd de door hem gemaakte
            buitengerechtelijke incassokosten in rekening te brengen.
          </li>
          <li>
            In geval van (redelijk zicht op) faillissement, liquidatie of
            surseance van betaling of een schuldsanering in het kader van de
            WSNP zijn de vorderingen van Haerst op Wederpartij en de
            (toekomstige) verplichtingen van Wederpartij jegens Haerst direct en
            volledig opeisbaar.
          </li>
          <li>
            Door Wederpartij gedane betalingen strekken steeds ter afdoening in
            de eerste plaats van alle verschuldigde rente en kosten, in de
            tweede plaats van opeisbare facturen die het langst open staan, ook
            indien Wederpartij anders aangeeft.
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h3> Artikel 12 - Klachten</h3>
        <ol>
          <li>
            Wederpartij kan op een gebrek in de prestatie geen beroep meer doen,
            indien hij niet binnen 2 (twee) maanden nadat hij het gebrek heeft
            ontdekt of redelijkerwijze had moeten ontdekken bij Haerst ter zake
            heeft geprotesteerd. Indien er sprake is van een zichtbaar gebrek
            bij (op)levering geldt een termijn van 48 (achtenveertig) uur.
          </li>
          <li>
            De Wederpartij dient Haerst in ieder geval 4 (vier) weken de tijd te
            geven om de klacht in onderling overleg op te lossen.
          </li>
          <li>
            Indien een klacht niet binnen de in de voorgaande leden vermelde
            termijnen aan Haerst is gemeld, wordt het product en/of de dienst
            geacht aan de overeenkomst te beantwoorden en conform de
            overeenkomst te functioneren.
          </li>
          <li>
            Klachten schorten de betalingsverplichting van de Wederpartij niet
            op.
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h3>Artikel 13 - Overdracht</h3>
        <ol>
          <li>
            Rechten van Wederpartij uit deze overeenkomst kunnen niet worden
            overgedragen zonder de voorafgaande schriftelijke instemming van de
            andere partij. Deze bepaling geldt als een beding met
            goederenrechtelijke werking zoals bedoeld in artikel 3:83 lid 2 BW.
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h3> Artikel 14 - Meerwerk</h3>
        <ol>
          <li>
            Indien Haerst op verzoek van Wederpartij of op haar eigen verzoek,
            met (stilzwijgende) instemming van Wederpartij, werkzaamheden of
            andere prestaties heeft verricht die buiten de inhoud of omvang van
            de overeenkomst vallen, zullen deze werkzaamheden of prestaties door
            Wederpartij worden vergoed volgens de gebruikelijke tarieven van
            Haerst. Wederpartij is nimmer verplicht aan een dergelijk verzoek te
            voldoen en hij kan verlangen dat daarvoor een afzonderlijke
            schriftelijke overeenkomst wordt gesloten.
          </li>
          <li>
            Wederpartij aanvaardt dat door werkzaamheden of prestaties als
            bedoeld in lid 1 van dit artikel de overeengekomen doelstellingen en
            verwachtingen kunnen worden beïnvloed.
          </li>
          <li>
            Voor zover voor de dienstverlening een vast bedrag is afgesproken
            zal Haerst Wederpartij altijd tevoren schriftelijk informeren over
            de financiële consequenties van het meerwerk.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 15 - Intellectueel eigendom</h3>
        <ol>
          <li>
            Alle intellectuele eigendomsrechten die betrekking hebben op en/of
            het resultaat zijn van de door Haerst verleende diensten rusten bij
            Haerst en/of haar licentiegevers of toeleveranciers. Wederpartij
            verkrijgt uitsluitend de niet-exclusieve gebruiksrechten die bij
            deze voorwaarden en de wet uitdrukkelijk worden toegekend. Ieder
            ander of verdergaand recht van Wederpartij is uitgesloten.
          </li>
          <li>
            De door Haerst verstrekte stukken aan Wederpartij zijn uitsluitend
            bestemd om te worden gebruikt door Wederpartij. Het is Wederpartij
            niet toegestaan om verkregen informatie openbaar te maken en/of te
            verveelvoudigen in welke vorm dan ook. Hieronder wordt onder andere
            verstaan het bewerken, verkopen, het ter beschikking stellen,
            verspreiden en het - al dan niet na bewerking - integreren in
            netwerken, uitgezonderd dat een dergelijke openbaarmaking en/of
            verveelvoudiging schriftelijk door Haerst is toegestaan en/of een
            dergelijke openbaarmaking en/of verveelvoudiging voortvloeit uit de
            aard van de overeenkomst met Haerst.
          </li>
          <li>
            Haerst behoudt het recht de vergaarde kennis die bij uitvoering van
            de werkzaamheden tot stand is gekomen te gebruiken voor andere
            doeleinden voor zover hierbij geen vertrouwelijke informatie van
            Wederpartij aan Derden wordt verstrekt.
          </li>
          <li>
            Tenzij anders overeengekomen, is de Wederpartij niet bevoegd om
            sublicenties te verlenen aan Derden.
          </li>
          <li>
            Wederpartij vrijwaart Haerst voor de aanspraken van Derden inzake
            intellectuele eigendomsrechten.
          </li>
          <li>
            Indien Wederpartij handelt in strijd met dit artikel is Wederpartij
            een direct opeisbare boete verschuldigd ter hoogte van 5.000,-
            (vijfduizend euro) onverminderd het recht van Haerst op
            schadevergoeding.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 16 - Beheer</h3>
        <ol>
          <li>
            Haerst is te allen tijde gerechtigd wijzigingen aan te brengen in de
            technische faciliteiten ten aanzien van de diensten. Haerst tracht
            wijzigingen en/of updates zo veel mogelijk buiten kantoortijden uit
            te voeren, maar is - met name indien onmiddellijke wijzigingen en/of
            updates noodzakelijk zijn voor het beschikbaar houden van haar
            dienst - daartoe niet gehouden.
          </li>
          <li>
            Wederpartij zal zich opstellen en gedragen conform hetgeen van een
            verantwoordelijk en zorgvuldig gebruiker van de dienst(en) mag
            worden verwacht.
          </li>
          <li>
            Wederpartij is steeds verantwoordelijk voor ieder gebruik -
            waaronder mede begrepen onbevoegd gebruik - dat wordt gemaakt van de
            aan haar verleende gebruiks- en toegangsrechten en zal zich
            opstellen en gedragen conform hetgeen van een verantwoordelijk en
            zorgvuldig (internet-)gebruiker verwacht mag worden.
          </li>
          <li>
            Wederpartij zal de door Haerst gegeven aanwijzingen voor het gebruik
            van de dienst(en) te allen tijde opvolgen.
          </li>
          <li>
            Haerst is gerechtigd de niet-technische faciliteiten van haar
            diensten te wijzigen. Een wijziging die naar het redelijk oordeel
            van Haerst een aanmerkelijke, niet-tijdelijke, aanpassing vereist
            aan de zijde van de Wederpartij, zal zo spoedig mogelijk aan
            Wederpartij kenbaar worden gemaakt. Wederpartij kan geen aanspraak
            maken op compensatie of vergoeding van schade, doch heeft het recht
            om de Overeenkomst alsdan op te zeggen met ingang van de dag van de
            aangekondigde wijziging.
          </li>
          <li>
            Haerst behoudt zich het recht voor om technische diensten te
            staken/verwijderen, indien deze een storing c.q. vertraging van het
            systeem veroorzaakt. Haerst beoordeelt of er sprake is van zodanige
            storing c.q. vertraging en kan zonder voorafgaand bericht aan
            Wederpartij de technische diensten blokkeren, dan wel anderszins
            maatregelen nemen om de storing c.q. vertraging op te heffen.
          </li>
          <li>
            Haerst is gerechtigd zonder voorafgaande bekendmaking haar diensten
            (tijdelijk) buiten gebruik te stellen of het gebruik ervan te
            beperken voor zover dit noodzakelijk is voor het redelijkerwijs
            benodigde onderhoud of voor de noodzakelijk door Haerst te
            verrichten aanpassingen of verbeteringen van de diensten zonder dat
            hierdoor een recht op schadevergoeding of compensatie van
            Wederpartij jegens Haerst ontstaat. Haerst tracht onderhoud,
            wijzigingen en/of updates zo veel mogelijk buiten kantoortijden uit
            te voeren, maar is - met name indien onmiddellijke wijzigingen en/of
            updates noodzakelijk zijn voor het beschikbaar houden van haar
            dienst - daartoe niet gehouden.
          </li>
          <li>
            Haerst is gerechtigd om Wederpartij de toegang tot de dienst en/of
            het product te beperken, op te schorten of geheel (tijdelijk of
            duurzaam) te ontzeggen indien Haerst het redelijke vermoeden heeft
            dat de dienst wordt gebruikt in strijd met de wet of deze
            overeenkomst, zonder dat Wederpartij daarbij enig beroep kan doen op
            schadevergoeding, onverminderd het recht van Haerst op
            schadevergoeding en/of ontbinding.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 17 - Geheimhouding</h3>
        <ol>
          <li>
            Geheimhouding van alle vertrouwelijke informatie, die Wederpartij in
            het kader van de overeenkomst van Haerst heeft verkregen, is
            verplicht voor Wederpartij. Informatie is vertrouwelijk indien dit
            door de Haerst is medegedeeld of als dit redelijkerwijs voortvloeit
            uit de aard van de informatie.
          </li>
          <li>
            Indien de Wederpartij lid 1 van deze bepaling overtreedt, is
            Wederpartij, ongeacht of de overtreding aan Wederpartij kan worden
            toegerekend en zonder voorafgaande ingebrekestelling of
            gerechtelijke procedure, ten behoeve van Haerst een direct opeisbare
            boete van 5.000,- (vijfduizend) euro verschuldigd voor elke
            overtreding zonder dat sprake hoeft te zijn van enige vorm van
            schade onverminderd de overige rechten van Haerst, onverminderd haar
            recht om naast de boete schadevergoeding te vorderen.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 18 - Verrekening en opschorting</h3>
        <ol>
          <li>
            Het opschortingsrecht en het recht van verrekening van Wederpartij
            zijn uitgesloten.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 19 - Toepasselijk recht</h3>
        <ol>
          <li>
            Op overeenkomsten tussen Haerst en Wederpartij waarop deze algemene
            voorwaarden betrekking hebben, is uitsluitend Nederlands recht van
            toepassing.
          </li>
          <li>
            Geschillen tussen Partijen zullen zoveel mogelijk door middel van
            goed overleg tot een oplossing worden gebracht. Alle geschillen
            tussen Wederpartij en Haerst zullen uitsluitend worden beslecht door
            de bevoegde rechter in het arrondissement waarin Haerst gevestigd
            is.
          </li>
        </ol>
      </div>
      <div className="mb-3">
        <h3>Artikel 20 - Survival</h3>
        <ol>
          <li>
            De bepalingen uit de algemene voorwaarden en de overeenkomst die de
            strekking hebben om na beëindiging van de overeenkomst hun gelding
            te behouden, waaronder begrepen maar niet beperkt tot artikel 7
            (Aansprakelijkheid), artikel 8 (overmacht), artikel 15
            (Intellectueel eigendom), artikel 17 (Geheimhouding), artikel 19
            (Toepasselijk recht) en deze bepaling (Survival), blijven na het
            eindigen van de overeenkomst onverminderd van kracht.
          </li>
        </ol>
      </div>

      <div className="mb-3">
        <h3>Artikel 21 - Wijziging of aanvulling</h3>
        <ol>
          <li>
            Haerst is gerechtigd om deze algemene voorwaarden eenzijdig te
            wijzigen of aan te vullen, zonder dat de Wederpartij het recht
            verkrijgt de overeenkomst eenzijdig te ontbinden. In dat geval zal
            Haerst Wederpartij tijdig op de hoogte stellen van de wijzigingen of
            aanvullingen.
          </li>
          <li>
            Tussen deze kennisgeving en de inwerkingtreding van de gewijzigde of
            aangevulde voorwaarden zullen minimaal 30 (dertig) dagen zitten.
          </li>
        </ol>
      </div>
    </>
  );
};

const TermsModal = ({ showTerms, setShowTerms, children }) => {
  const termsModalRef = useRef(null);
  // console.log("showEnable2faModal:", showEnable2faModal);

  // console.log("showTerms:", showTerms);
  // console.log("ref:", termsModalRef);

  useEffect(() => {
    if (showTerms) {
      termsModalRef.current.openModal();
    }
  }, [showTerms]);

  return (
    <>
      <OverlayModalv2
        ref={termsModalRef}
        type="large"
        contentClasses="p-8"
        disableScroll={false}
        trackClosedModal={() => setShowTerms(false)}
      >
        {/* <StateView values={values} title="Values" /> */}
        {/* <h3 className="mb-4">Terms & Conditions</h3> */}
        {/* {children} */}
        <Content />
      </OverlayModalv2>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.Organisation.org_login.id,
  };
};

const mapDispatchToProps = {
  enable2fa,
  toggle2faIsEnabledFromProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsModal);
