import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { _useOnClickOutside } from "../../utils/helper";
import { iconClose_White } from "./Icons";
// import { StateView } from "../../utils/StateView";

const OverlayModal = forwardRef(
  (
    {
      children,
      type,
      contentClasses,
      modalClasses,
      disableScroll,
      trackClosedModal,
      disableCloseModal,
    },
    ref
  ) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const overlayModalRef = useRef(null);

    useImperativeHandle(
      ref,
      () => ({
        openModal,
        closeModal,
      }),
      []
    );

    const openModal = () => {
      setIsModalVisible(true);
    };

    const closeModal = () => {
      setIsModalVisible(false);

      if (trackClosedModal) {
        trackClosedModal();
      }
    };

    _useOnClickOutside(overlayModalRef, () => {
      if (isModalVisible && !disableCloseModal) {
        // console.log("clickoutside feature!");
        setIsModalVisible(false);
        trackClosedModal();
      }
    });

    const defaultCss = "w-3/4 sm:w-2/3 md:w-1/3 2xl:w-1/4 max-w-[500px]";

    const largeCss = "w-3/4 sm:w-2/3 2xl:w-1/2 max-w-[1000px]";

    /**
     * TIP: SETTING THE HEIGHT OF THE MODAL
     * - Scrolling Modal
     *   Pre-designed based on long modal content (with a defined min-height)
     *
     * - Non-scrolling Modal:
     *   This is suitable for small modals where height is based on modal content
     *   > Set 'modalClasses'='min-h-auto'
     *   > Set 'disableScroll'={true}
     */

    return (
      <div
        className={`fixed z-10 inset-0 w-full h-full bg-mirage transform transition-all duration-150 ${
          isModalVisible
            ? "bg-opacity-75 pointer-events-auto"
            : "bg-opacity-0 pointer-events-none"
        }
      `}
      >
        <div
          className={`bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md ${
            type === "large" ? largeCss : defaultCss
          } ${modalClasses ? modalClasses : "min-h-[600px] max-h-[80vh]"}
        ${isModalVisible ? "block" : "hidden"}`}
          ref={overlayModalRef}
        >
          {/* <StateView values={isModalVisible} title="isModalVisible" />
          <StateView values={disableCloseModal} title="disableCloseModal" /> */}

          <div className={`${disableScroll ? "block" : "absolute inset-0"}`}>
            {!disableCloseModal && (
              <img
                src={iconClose_White}
                alt=""
                className="w-12 h-12 absolute top-0 right-0 transform -translate-y-full translate-x-full cursor-pointer"
                onClick={() => closeModal()}
              />
            )}
            <div className="rounded-md overflow-hidden h-full">
              <div
                className={`relative h-full overflow-y-auto ${
                  contentClasses ? contentClasses : "p-6 pt-8 pb-12"
                }`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default OverlayModal;
