import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { logoutSystem } from "../../redux/actions";
// Components
import { OverlayModalv2 } from "../../components/atoms";
// Utilities
// import { StateView } from "../../../utils/StateView";

const TokenExpirationModal = ({ isTokenExpired, ...props }) => {
  const tokenExpirationModal = useRef(null);
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (isTokenExpired) {
      tokenExpirationModal.current.openModal();
      // Timer counter
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        // console.log("You are logged out now!");
        tokenExpirationModal.current.closeModal();
        props.logoutSystem();
      }
    }
  }, [isTokenExpired, counter]);

  // useEffect(() => {
  //   counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  // }, [counter]);

  return (
    <>
      <OverlayModalv2
        ref={tokenExpirationModal}
        modalClasses="h-auto min-h-[150px]"
        contentClasses="p-8"
        disableScroll={true}
        disableCloseModal={true}
      >
        {/* <StateView values={values} title="Values" /> */}
        <div className="space-y-2 flex flex-col items-center">
          <h2 className="">Sorry, your token has expired</h2>
          <p className="">
            You'll be logged out in{" "}
            <span className="font-semibold">{counter} seconds</span>. Please
            login again.
          </p>
          <p></p>
        </div>
      </OverlayModalv2>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isTokenExpired: state.Auth.isTokenExpired,
  };
};

const mapDispatchToProps = {
  logoutSystem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TokenExpirationModal);
