import React from "react";
import { iconArrowLeft_Navy } from "./Icons";
import { useHistory } from "react-router-dom";

const BackButton = (props) => {
  let history = useHistory();

  const { fromImg } = props;

  let inputClass = "absolute top-5 left-5 my-0";

  return (
    <button
      className={`${fromImg && inputClass} bg-white border border-gray-200 hover:border-primary transform transition-colors duration-150 rounded-full w-10 h-10 flex justify-center items-center my-2`}
      onClick={() => history.goBack()}
    >
      <img src={iconArrowLeft_Navy} alt="" />
    </button>
  );
};

export default BackButton;
