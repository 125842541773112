import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
// Redux Actions
import { loginOrganisation, logoutOrganisation } from "../../redux/actions";
// Assets
import { iconChevronUp_Navy, iconLogout_Navy } from "../atoms/Icons";
// Utilities
import api from "../../utils/api";
import { _useOnClickOutside } from "../../utils/helper";

const OrganisationSelector = ({
  orgLogin,
  orgUserType,
  userType,
  orgPortalView,
  ...props
}) => {
  const orgDropdownEl = useRef();
  const history = useHistory();

  const [orgData, setOrgData] = useState([]);

  const [toggleOrg, setToggleOrg] = useState(false);

  const _getListOfOrganisations = async () => {
    const dynamicUrl =
      orgUserType === "super_admin"
        ? "superadmin/list/organisation/0/25/filter/sort/name/asc"
        : "organisation/list/portal";

    try {
      const response = await api.post(dynamicUrl, {});
      const { data } = response.data.result;

      setOrgData(_transformOrgOptions(data));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(
    () => {
      _getListOfOrganisations();
    },
    // eslint-disable-next-line
    []
  );

  const _transformOrgOptions = (data) => {
    const transformedData = data.map((org) => {
      const { id, logo, invitation_link, name } = org;
      return {
        id,
        name,
        logo,
        invitation_link,
      };
    });

    if (orgUserType === "super_admin") {
      return transformedData;
    } else {
      const acceptedOrganisations = transformedData.filter(
        (org) => org.invitation_link === null
      );

      return acceptedOrganisations;
    }
  };

  const _loginOrganisation = async (organisation_id) => {
    try {
      const response = await api.post(
        `auth/organisation/login/${organisation_id}`,
        {}
      );
      const { data } = response;

      setToggleOrg(false);
      toast.success(data.message);
      props.loginOrganisation(data.result);

      if (orgPortalView === "admin") {
        history.push("/org/");
      } else {
        history.push(`/org/videos`);
      }
    } catch (error) {
      const { data } = error;

      toast.error(data.message);
      console.log("error", error);
    }
  };

  const _logoutOrganisation = () => {
    setToggleOrg(false);
    props.logoutOrganisation();
    return history.push("/");
  };

  const _toggleDropdown = useCallback(
    () => {
      setToggleOrg((value) => !value);
    },
    // eslint-disable-next-line
    [toggleOrg]
  );

  const sharedTransition = "transition-all duration-500 ease-in-out";

  _useOnClickOutside(orgDropdownEl, () => setToggleOrg(false));

  /**
   * TEMPORARY FIX
   * This needs to only scroll just below the header when the list extends below the viewport height
   * Since Super Admin is the only user that will see this issue first as the organisation grows
   */
  const enableScrollFroSuperAdminOnly =
    userType === "super_admin" ? (toggleOrg ? "auto" : "hidden") : "hidden";

  return (
    <React.Fragment>
      {/* <div className="absolute inset-0 z-[99] bg-gray-300"></div> */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-mirage transform transition-opacity duration-150 ${
          toggleOrg ? "bg-opacity-75" : "bg-opacity-0 pointer-events-none"
        }`}
      >
        <div
          ref={orgDropdownEl}
          className={`w-[250px] absolute top-0 left-0 bg-white flex flex-col overflow-${enableScrollFroSuperAdminOnly} min-h-[5rem] ${sharedTransition}
            ${toggleOrg ? "max-h-screen" : "max-h-20 pointer-events-auto"} `}
        >
          <div
            className="flex items-center min-h-[5rem] h-20 border-b-2 border-gray-200 hover:cursor-pointer"
            onClick={() => orgData.length > 1 && _toggleDropdown()}
          >
            {orgData.length > 1 ? (
              <React.Fragment>
                <img
                  src={iconChevronUp_Navy}
                  alt=""
                  className={`h-2/3 ${sharedTransition} transform ${
                    toggleOrg && "rotate-180"
                  }`}
                />
                <div className="w-full max-h-[50px] min-h-[40px] max-w-[180px] relative after:[content:''] after:block after:pt-[20%]">
                  <img
                    src={orgLogin.logo}
                    alt=""
                    className="absolute top-1/2 left-0 -translate-y-1/2 h-full w-auto object-contain"
                  />
                </div>
              </React.Fragment>
            ) : (
              <div className="ml-8 w-full max-h-[50px] min-h-[40px] max-w-[180px] relative after:[content:''] after:block after:pt-[20%]">
                <img
                  src={orgLogin.logo}
                  alt=""
                  className="absolute top-1/2 left-0 -translate-y-1/2 h-full w-auto object-contain"
                />
              </div>
            )}
          </div>
          {orgData
            .filter((fetchedOrg) => fetchedOrg.id !== orgLogin.id)
            .map((org, i) => {
              return (
                <div
                  key={i}
                  className="flex items-center min-h-[5rem] h-20 border-b-2 border-gray-200 hover:cursor-pointer"
                  onClick={() => _loginOrganisation(org.id)}
                >
                  <div className="ml-12 w-full max-h-[50px] min-h-[40px] max-w-[180px] relative after:[content:''] after:block after:pt-[20%]">
                    <img
                      src={org.logo}
                      alt=""
                      className="absolute top-1/2 left-0 -translate-y-1/2 h-full w-auto object-contain"
                    />
                  </div>
                  {/* <div className="flex-1 flex items-center justify-start h-full pl-12">
                    <img src={org.logo} alt="" className="h-2/3 max-h-[50px]" />
                  </div> */}
                </div>
              );
            })}
          {orgUserType === "super_admin" && (
            <div
              className="flex items-center justify-center min-h-[5rem] h-20 border-b-2 border-gray-200 group hover:cursor-pointer"
              onClick={() => _logoutOrganisation()}
            >
              <div className="flex items-center transition-opacity duration-150 group-hover:opacity-75">
                <img src={iconLogout_Navy} alt="" className="h-10" />
                <p className="">Super admin portal</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    orgLogin: state.Organisation.org_login,
    orgUserType: state.Organisation.org_login.orgUserType,
    userType: state.Auth.userType,
    orgPortalView: state.Organisation.org_portal_view,
  };
};

const mapDispatchToProps = {
  loginOrganisation,
  logoutOrganisation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationSelector);
