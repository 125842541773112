import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { FieldWrapperInline, InputReadOnly } from ".";
import { iconPencil_Navy, iconPencil_White } from "../atoms/Icons";
import { StateView } from "../../utils/StateView";

const InlineInputText = (props) => {
  // fixes error of custom prop needs to be converted to string when spread props
  const { isReadonly, asyncFn, displayValue, editMode, ...filteredProps } =
    props;

  const inlineInputEl = useRef();
  // console.log("ref:", inlineInputEl);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused) {
      inlineInputEl.current.focus();
      // console.log("focus");
    }
  }, [isFocused]);

  const _keyDownHandler = (e) => {
    const inputValue = e.target.value;

    if (isFocused && e.key === "Enter") {
      asyncFn(inputValue, e.target.name);
      setIsFocused(false);
      e.target.value = "";
    }
  };

  const _handleBlur = (e) => {
    const inputValue = e.target.value;

    if (isFocused) {
      asyncFn(inputValue, e.target.name);
      setIsFocused(false);
      e.target.value = "";
    }
  };

  const inputClasses = `w-48 outline-8 outline-red-400 block ${
    props.label && "mt-1"
  } px-1 py-2 h-7 rounded-sm focus-visible:outline-pictonblue bg-white text-primary`;

  // console.log('displayValue:', displayValue);
  // console.log(filteredProps);
  return (
    <React.Fragment>
      {/* <StateView values={isFocused} title="isFocused" /> */}
      {(!!props.isReadonly && (
        <InputReadOnly
          label={props.label}
          value={props.value}
          error={_.isString(props.error) && props.error}
        />
      )) || (
        <FieldWrapperInline
          label={props.label}
          error={_.isString(props.error) && props.error}
          alignment={props.alignment}
        >
          {isFocused ? (
            <input
              className={inputClasses}
              ref={inlineInputEl}
              {...filteredProps}
              placeholder={props.placeholder}
              onBlur={(e) => _handleBlur(e)}
              onKeyDown={(e) => _keyDownHandler(e)}
            />
          ) : (
            <div className="flex items-center h-7">
              <p className={`${props.classes}`}>{displayValue}</p>
              {editMode && (
                <div
                  className={`w-10 min-w-[2.5rem] h-7 relative hover:cursor-pointer group`}
                  onClick={() => setIsFocused(true)}
                >
                  <img
                    className={`w-full absolute group-hover:opacity-50 ${
                      displayValue.length === 0
                        ? "left-0 -translate-x-[30%]"
                        : "left-1/2 -translate-x-[50%]"
                    } top-1/2 -translate-y-[50%]`}
                    src={props.mode === "light" ? iconPencil_Navy : iconPencil_White}
                    alt=""
                  />
                </div>
              )}
            </div>
          )}
        </FieldWrapperInline>
      )}
    </React.Fragment>
  );
};

export default InlineInputText;
