import * as actionTypes from "../actions/action_types";
import produce from "immer";

const INITIAL_STATE = {
  auto_edit_mode: false,
  id: null,
  title: "",
  // subject: "",
  description: "",
  tags: [],
  interviewer: {},
  interviewee: {},
  subtitle: "",
  isViewable: null,
  url: "",
  thumbnail: "",
  size: "",
  starred: null,
  datetime: null,
  metadata: null,
  recorded_at: "",
  recorded_by: {},
  created_at: "",
  updated_at: "",
  length: "",
  priority: "",
  shared_by: null,
  expired_at: null,
};

export default function currentvideoReducer(state = INITIAL_STATE, action) {
  // console.log('action CV:', action);
  switch (action.type) {
    case actionTypes.SET_CURRENT_VIDEO:
      return produce(state, (draft) => {
        draft.id = action.payload.id;
        draft.uuid = action.payload.uuid;
        draft.title = action.payload.title;
        // draft.subject = action.payload.subject;
        draft.description = action.payload.description;
        draft.tags = action.payload.tags;
        draft.interviewer = action.payload.interviewer;
        draft.interviewee = action.payload.interviewee;
        draft.subtitle = action.payload.subtitle;
        draft.isViewable = action.payload.isViewable;
        draft.url = action.payload.url;
        draft.thumbnail = action.payload.thumbnail;
        draft.size = action.payload.size;
        draft.starred = action.payload.starred;
        draft.datetime = action.payload.datetime;
        draft.metadata = action.payload.metadata;
        draft.recorded_at = action.payload.recorded_at;
        draft.recorded_by = action.payload.recorded_by;
        draft.created_at = action.payload.created_at;
        draft.updated_at = action.payload.updated_at;
        draft.length = action.payload.length;
        draft.priority = action.payload.priority;
        draft.shared_by = action.payload.shared_by;
        draft.expired_at = action.payload.expired_at;
      });

    case actionTypes.SET_CURRENT_VIDEO_FIELD:
      return produce(state, (draft) => {
        const { fieldType } = action;
        // console.log("action, fieldType:", action, fieldType);

        if (fieldType === "title") {
          draft.title = action.payload.title;
          // } else if (fieldType === "subject") {
          //   draft.subject = action.payload.subject;
        } else if (fieldType === "description") {
          draft.description = action.payload.description;
        } else if (fieldType === "tags") {
          draft.tags = action.payload.tags;
        } else if (fieldType === "interviewee") {
          draft.interviewee = action.payload.interviewee;
        } else if (fieldType === "starred") {
          draft.starred = action.payload.starred;
        } else if (fieldType === "priority") {
          draft.priority = action.payload.priority;
        }
      });

    case actionTypes.SET_AUTO_EDIT_MODE:
      return produce(state, (draft) => {
        draft.auto_edit_mode = action.payload;
      });

    default:
      return state;
  }
}
