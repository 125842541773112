import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button } from "../atoms";

const baseStyle = {
  transition: "border 150ms ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const InputFileUpload = ({ preview, uploadAction, label, ...props }) => {
  const { t } = useTranslation("common");

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      uploadAction(acceptedFiles[0]);
    },
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg"],
    },
    // getFilesFromEvent: (event) => getEvent(event),
  });

  // const getEvent = async (event) => {
  //   console.log("getEvent", event);
  // };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => URL.revokeObjectURL(preview);
  }, [preview]);

  // const files = acceptedFiles.map((file, errors) => {
  //   console.log("file", file);
  //   console.log("errors", errors);

  //   return (
  //     <li key={file.path}>
  //       {file.path} - {file.size} bytes
  //     </li>
  //   );
  // });

  const dropzoneStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="flex items-center justify-between mb-2">
      <div className="w-5/12 max-w-[350px]">{label}</div>
      <div className="flex-1 w-7/12">
        <div className="flex items-center gap-8 max-w-[450px]">
          <div
            {...getRootProps({
              className:
                "relative w-40 border-2 border-transparent bg-gray-200 overflow-hidden rounded-lg hover:cursor-pointer after:content:[''] after:w-full after:block after:pt-[100%]",
              style: dropzoneStyle,
            })}
          >
            <input {...getInputProps()} />
            {preview ? (
              <div className="absolute w-full h-full flex items-center">
                <img
                  src={preview}
                  className="w-full object-contain"
                  alt=""
                ></img>
              </div>
            ) : (
              <p className="w-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                Drag 'n' drop file here
                <br /> (.jpg or .png only)
              </p>
            )}
          </div>
          <div className="h-full space-y-4">
            <Button
              type="tertiary"
              twWidth="w-48"
              onClick={open}
              disabled={preview && true}
            >
              {t("buttons.upload")}
            </Button>
            {preview && (
              <Button
                type="tertiary"
                twWidth="w-48"
                onClick={() => {
                  // console.log("click");
                  uploadAction("");
                }}
              >
                {t("buttons.remove")}
              </Button>
            )}
          </div>
        </div>
        {_.isString(props.error) && (
          <div className="form-error text-red-500 text-sm mt-2">
            {props.error}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFileUpload;
