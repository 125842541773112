import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { FieldWrapperInline, InputReadOnly } from ".";
import { iconSearch_Navy } from "../atoms/Icons";
import { StateView } from "../../utils/StateView";

const InputSearchTable = (props) => {
  const inlineInputEl = useRef();
  // console.log("ref:", inlineInputEl);
  const [isFocused, setIsFocused] = useState(false);

  // fixes error of custom prop needs to be converted to string when spread props
  const { searchFn, getAllFn, ...filteredProps } = props;

  const _keyDownHandler = (e) => {
    if (isFocused && e.key === "Enter") {
      if (filteredProps.value.length === 0) {
        // console.log("get all");
        getAllFn(1);
      } else {
        // console.log("search keyword");
        searchFn();
      }
    }
  };

  const _handleClick = () => {
    if (filteredProps.value.length === 0) {
      // console.log("get all");
      getAllFn(1);
    } else {
      // console.log("search keyword");
      searchFn();
    }
  };

  const inputClasses = `block w-full px-1 py-3 outline-0`;

  // console.log('displayValue:', displayValue);
  // console.log(filteredProps);
  return (
    <React.Fragment>
      {/* <StateView values={isFocused} title="isFocused" /> */}

      <div
        className={`flex bg-white border border-gray-200 rounded-md max-h-[50px] overflow-hidden outline ${
          isFocused ? "outline-pictonblue" : "outline-transparent"
        }`}
      >
        <img
          src={iconSearch_Navy}
          alt=""
          className="w-16 hover:cursor-pointer"
          onClick={() => _handleClick()}
        />
        <input
          className={inputClasses}
          ref={inlineInputEl}
          {...filteredProps}
          placeholder={props.placeholder}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => _keyDownHandler(e)}
          onClick={() => setIsFocused(true)}
        />
      </div>
    </React.Fragment>
  );
};

export default InputSearchTable;
