import React, { useEffect, useState } from "react";
import _, { filter } from "lodash";
import { FieldWrapper } from ".";
import TermsModal from "../../screens/Terms/TermsModal";
import { StateView } from "../../utils/StateView";
import axios from "axios";
import { useTranslation } from "react-i18next";

const InputTermsAndConditions = React.forwardRef((props, ref) => {
  const { t } = useTranslation("account");

  const [showTerms, setShowTerms] = useState(false);
  const [jsonData, setJsonData] = useState([]);

  const _fetchTerms = async () => {
    try {
      const response = await axios.get("data/terms-and-conditions.json");

      const { data } = response;

      setJsonData(data);
      // console.log("response:", response);
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    _fetchTerms();
  }, []);

  // console.log("jsonData:", jsonData);

  // fixes error of custom prop needs to be converted to string when spread props
  // const { isReadonly, ...filteredProps } = props;
  const { ...filteredProps } = props;

  const active = props.checked ? "opacity-100" : "opacity-0";

  return (
    <>
      <div className="mb-2">
        {/* <StateView values={showTerms} /> */}
        <div className="flex">
          <div className="relative w-6 h-6 z-10 bg-[#ccc] rounded-sm">
            <span
              className={`block relative bg-pictonblue w-full h-full
              after:content-[""] after:absolute after:left-1/2 after:top-[45%] after:-translate-x-1/2 after:-translate-y-1/2 after:w-[30%] after:h-[60%] after:border after:border-white after:border-t-0 after:border-r-[3px] after:border-b-[3px] after:border-l-0 after:transform after:rotate-45
              ${active} transform transition-opacity duration-150`}
            ></span>
            <input
              className="absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer"
              type={props.type}
              ref={ref}
              {...filteredProps}
            ></input>
          </div>
          <label title={props.title} className="pl-2">
            <div>
              {t("i_agree_to")}{" "}
              <span
                className="underline underline-offset-2 transform transition-opacity hover:opacity-75 hover:cursor-pointer"
                onClick={(e) => setShowTerms(true)}
              >
                {props.label}
              </span>
            </div>
          </label>
        </div>
        {_.isString(props.error) && (
          <div className="form-error text-red-500 text-sm mt-2">
            {props.error}
          </div>
        )}
        <TermsModal
          showTerms={showTerms}
          setShowTerms={setShowTerms}
        ></TermsModal>
      </div>
    </>
  );
});

export default InputTermsAndConditions;
