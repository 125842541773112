import React, { useRef, forwardRef, useImperativeHandle, useState } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { FieldWrapper } from "../forms";
import { iconPriorityRed } from "../atoms/Icons";

const InputAsyncSelect = forwardRef((props, ref) => {
  const inputAsyncRef = useRef();
  const [isClearable, setIsClearable] = useState(true);

  useImperativeHandle(
    ref,
    () => {
      return {
        clear() {
          inputAsyncRef.current.clearValue();
        },
        setValue(value) {
          inputAsyncRef.current.setValue(value, "select-option");
        },
      };
    },
    []
  );

  // console.log("ref forwardRed:", ref);

  const _handleChange = (option) => {
    // console.log("option InputSelect:", option);
    if (_.isArray(option)) {
      let selectedOption = [];
      option.forEach((selected) => selectedOption.push(selected));
      // console.log("A", props.name, selectedOption);
      props.onChange(props.name, selectedOption);
    } else if (option !== null) {
      // console.log("B", props.name, option);
      props.onChange(props.name, option);
    } else if (option === null && props.isMulti) {
      // console.log("C", props.name, []);
      props.onChange(props.name, []);
    } else {
      // console.log("D", props.name, "");
      props.onChange(props.name, "");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const { isPriorityFieldSelected, displayPriority } = props;

  /**
   * Weird Behaviour
   * - isClearable either returns null or removes the prop 'name' from the local state.
   * Future investigation
   */
  return (
    <FieldWrapper
      label={props.label}
      error={_.isString(props.error) && props.error}
      wrapperCss={!!props.wrapperCss && props.wrapperCss}
      alignment={props.alignment}
    >
      <div className="relative">
        <AsyncSelect
          ref={inputAsyncRef}
          isDisabled={props.isDisabled}
          isClearable={isClearable}
          {...props}
          // value={(e) => console.log("value")}
          onChange={(option) => _handleChange(option)}
          className="react-async_select__container"
          classNamePrefix="react-select"
        />
        {displayPriority && !isPriorityFieldSelected && (
          <div className="w-12 h-12 absolute -right-0 translate-x-full top-1/2 -translate-y-1/2">
            <img src={iconPriorityRed} alt="" className="w-full h-full" />
          </div>
        )}
      </div>
    </FieldWrapper>
  );
});

export default InputAsyncSelect;
