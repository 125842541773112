import React from "react";
import _ from "lodash";
import { FieldWrapper, InputReadOnly } from ".";

const InputGroup = React.forwardRef((props, ref) => {
  // fixes error of custom prop needs to be converted to string when spread props
  const { isReadonly, isDisabled, ...filteredProps } = props;

  const inputClasses = `block w-full px-4 py-5 rounded-md max-w-[450px] border border-gray-200 focus-visible:outline-pictonblue ${
    isDisabled && "opacity-50"
  }`;

  // console.log(filteredProps);
  return (
    <React.Fragment>
      {(!!props.isReadonly && (
        <InputReadOnly
          label={props.label}
          value={props.value}
          error={_.isString(props.error) && props.error}
        />
      )) || (
        <FieldWrapper
          label={props.label}
          error={_.isString(props.error) && props.error}
          alignment={props.alignment}
        >
          {props.type === "textarea" ? (
            <textarea
              className={inputClasses}
              ref={ref}
              {...filteredProps}
              placeholder={props.placeholder}
              rows={`${props.rows ? props.rows : 5}`}
            ></textarea>
          ) : (
            <input
              disabled={isDisabled}
              className={inputClasses}
              ref={ref}
              {...filteredProps}
              placeholder={props.placeholder}
            />
          )}
        </FieldWrapper>
      )}
    </React.Fragment>
  );
});

export default InputGroup;
