import React from "react";

// Style
import DataTableStyled from "./style";

const DataTable = ({ ...props }) => {
  return props.portalType === "organisation" ? (
    <DataTableStyled {...props}></DataTableStyled>
  ) : (
    <div className="data-table-container relative px-8 before:[content:''] before:bg-gray-100 before:block before:absolute before:top-0 before:left-0 before:w-full before:h-[175px]">
      <DataTableStyled {...props}></DataTableStyled>
    </div>
  );
};

export default DataTable;
