import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// Components
import { iconMail_Navy } from "../../atoms/Icons";
// Utilities
import api from "../../../utils/api";
// import { StateView } from "../../../utils/StateView";

const InvitationStatus = ({ input, orgId, userId }) => {
  const [badge, setBadge] = useState("");

  useEffect(() => {
    setBadge(input);
  }, [input]);

  const _handleClickInvitation = async () => {
    const formData = {
      user_id: userId,
    };

    try {
      const response = await api.post(
        `organisation/${orgId}/sendInvite`,
        formData
      );

      const { message } = response.data;
      toast.success(message);
    } catch (error) {
      const { message } = error.data;
      console.log("error:", error);
      toast.error(message);
    }
  };

  return (
    badge &&
    badge.length !== 0 && (
      <>
        {/* <StateView values={input} title="Starred Input" /> */}
        {input === "no_response" && (
          <div className="w-12 h-12 relative">
            <img
              title="Resend Invite?"
              src={iconMail_Navy}
              alt=""
              className="w-full h-full hover:cursor-pointer hover:opacity-75"
              onClick={() => _handleClickInvitation()}
            />
          </div>
        )}
      </>
    )
  );
};

export default InvitationStatus;
