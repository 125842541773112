import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import _ from "lodash";
import { FieldWrapper } from "../forms";

const InputSelect = forwardRef((props, ref) => {
  const inputSelectRef = useRef();
  const [isClearable, setIsClearable] = useState(props.isClearable);

  useImperativeHandle(
    ref,
    () => {
      return {
        clear() {
          inputSelectRef.current.clearValue();
        },
        setValue(value) {
          inputSelectRef.current.setValue(value, "select-option");
        },
      };
    },
    []
  );

  const _handleChange = (option) => {
    // console.log('option InputSelect:', option);
    if (_.isArray(option)) {
      let selectedOption = [];
      option.forEach((selected) => selectedOption.push(selected));
      // console.log("A", props.name, selectedOption)
      props.onChange(props.name, selectedOption);
    } else if (option !== null) {
      // console.log("B", props.name, option)
      props.onChange(props.name, option);
    } else if (option === null && props.isMulti) {
      // console.log("C", props.name, [])
      props.onChange(props.name, []);
    } else {
      // console.log("D", props.name, "")
      props.onChange(props.name, "");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // console.log('props.value:', props.value);

  return (
    <FieldWrapper
      label={props.label}
      error={_.isString(props.error) && props.error}
      wrapperCss={!!props.wrapperCss && props.wrapperCss}
      alignment={props.alignment}
    >
      <Select
        ref={inputSelectRef}
        isDisabled={props.isDisabled}
        isClearable={isClearable}
        {...props}
        onChange={(option) => _handleChange(option)}
        className="react-select__container"
        classNamePrefix="react-select"
      />
    </FieldWrapper>
  );
});

export default InputSelect;
