import React, { useEffect, useRef, useState } from "react";
import _, { result } from "lodash";
import { FieldWrapperInline, InputReadOnly } from ".";
import { iconPencil_Navy, iconPencil_White } from "../atoms/Icons";
import { StateView } from "../../utils/StateView";

const InlineInputSearch = (props) => {
  // fixes error of custom prop needs to be converted to string when spread props
  const {
    isReadonly,
    asyncFn,
    options,
    selectOption,
    displayValue,
    clearSearchAndResult,
    searchEntered,
    setSearchEntered,
    editMode,
    ...filteredProps
  } = props;

  const inlineInputEl = useRef();
  // console.log("ref:", inlineInputEl);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused) {
      inlineInputEl.current.focus();
      // console.log("focus");
    }
  }, [isFocused]);

  const _keyDownHandler = (e) => {
    if (e.key === "Enter") {
      setSearchEntered(true);
      // Remove white spaces
      const searchInput = e.target.value;
      asyncFn(searchInput);
    }
  };

  const _handleBlur = (e) => {
    // If no result - Clear search and result
    if (options.length === 0) {
      // console.log("A");
      clearSearchAndResult();
      setIsFocused(false);
      setSearchEntered(false);
    }
    // Typed input and got result - Clear search and result
    // if (options.length > 0 && props.value.length > 0) {
    //   console.log("B");
    //   clearSearchAndResult();
    //   setIsFocused(false);
    // }
  };

  const inputClasses = `w-48 outline-8 outline-red-400 block ${
    props.label && "mt-1"
  } px-1 py-2 h-7 rounded-sm focus-visible:outline-pictonblue bg-white text-primary`;

  return (
    <React.Fragment>
      {/* <StateView values={isFocused} title="isFocused" /> */}
      {(!!props.isReadonly && (
        <InputReadOnly
          label={props.label}
          value={displayValue}
          error={_.isString(props.error) && props.error}
        />
      )) || (
        <FieldWrapperInline
          label={props.label}
          error={_.isString(props.error) && props.error}
          alignment={props.alignment}
        >
          {isFocused ? (
            <div className="relative text-black">
              <div className="flex items-center gap-2">
                <input
                  className={inputClasses}
                  ref={inlineInputEl}
                  {...filteredProps}
                  placeholder={props.placeholder}
                  onKeyDown={(e) => _keyDownHandler(e)}
                  onBlur={(e) => _handleBlur(e)}
                />
                <i className="text-xs opacity-50 text-white">
                  Press enter to search
                </i>
              </div>
              {searchEntered && (
                <div className="absolute z-[1] -bottom-2 transform translate-y-full left-0 min-w-[12rem] min-h-[4rem] max-h-[8rem] overflow-x-hidden overflow-y-auto bg-white rounded-md">
                  {options.length === 0 ? (
                    <div className="min-h-[4rem] flex justify-center items-center border border-black">
                      No results
                    </div>
                  ) : (
                    options.map((option) => {
                      return (
                        <div
                          key={option.id}
                          className="w-full p-2 hover:bg-pictonblue-highlight hover:cursor-pointer"
                          onClick={() => {
                            selectOption(option);
                            setIsFocused(false);
                          }}
                        >
                          <p className="font-medium">{option.name}</p>
                          <p className="text-sm">{option.bsn}</p>
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center h-7">
              <p className={props.classes}>{displayValue}</p>
              {editMode && (
                <div
                  className={`w-10 min-w-[2.5rem] h-7 relative hover:cursor-pointer group`}
                  onClick={() => setIsFocused(true)}
                >
                  <img
                    className={`w-full absolute group-hover:opacity-50 ${
                      displayValue.length === 0
                        ? "left-0 -translate-x-[30%]"
                        : "left-1/2 -translate-x-[50%]"
                    } top-1/2 -translate-y-[50%]`}
                    src={props.mode === "light" ? iconPencil_Navy : iconPencil_White}
                    alt=""
                  />
                </div>
              )}
            </div>
          )}
        </FieldWrapperInline>
      )}
    </React.Fragment>
  );
};

export default InlineInputSearch;
