import React from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// Redux Actions
import {
  logoutSystem,
  toggleOrganisationPortalView,
} from "../../redux/actions";
// Components
import { Button, Container } from "../atoms";
import OrganisationSelector from "./OrganisationSelector";
// Assets
import siteLogo from "../../assets/images/site-logo.svg";
import {
  iconSettings_Navy,
  iconHelp_Navy,
  iconLogout_Navy,
  iconToggleUser_Navy,
  iconToggleAdmin_Navy,
} from "../atoms/Icons";
// Utilities
import api from "../../utils/api";

// const LogoLink = React.forwardRef(({ navigate, twClass, handleClick, ...props }, ref) => {
// console.log('props :', props);
//   return (
//     <a ref={ref} {...props} onClick={handleClick} className={twClass}>
//       {props.children}
//     </a>
//   );
// });

const Header = ({
  fullName,
  orgUserType,
  orgId,
  orgLogo,
  orgPortalView,
  orgName,
  appUserType,
  currentLoginPic,
  ...props
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(["header"]);

  // console.log("appUserType:", appUserType);

  const _logoutAll = () => {
    api
      .post("auth/logout", {})
      .then((response) => {
        const { success, message } = response.data;

        toast.success(message);

        if (success) {
          props.logoutSystem();
        }
        // console.log("response:", response);
      })
      .catch((error) => {
        const { message } = error.data;

        toast.error(message);

        props.logoutSystem();
        // console.log("error:", error);
      });
  };

  const _toggleView = (typeOfOrgUser) => {
    switch (typeOfOrgUser) {
      case "user":
        props.toggleOrganisationPortalView("admin");
        break;
      case "admin":
        props.toggleOrganisationPortalView("user");
        break;
      default:
        props.toggleOrganisationPortalView("user");
        break;
    }
    history.push("/org/videos"); // Default to Videos page
  };

  const _handleLangChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="absolute z-[1] top-0 left-0 h-20 w-full bg-white border-b-2 border-gray-200">
      <div className="flex h-full">
        <div className="w-[250px] pl-8 flex items-center">
          {orgId ? (
            <OrganisationSelector />
          ) : (
            <div
              className="h-1/2 hover:cursor-pointer"
              onClick={() => history.push("/")}
            >
              {orgLogo ? (
                <img src={orgLogo} alt="" className="h-full max-h-[50px]" />
              ) : (
                <img src={siteLogo} alt="" className="h-full max-h-[50px]" />
              )}
            </div>
          )}
        </div>
        <div className="flex-1 h-full">
          <Container classes="h-full">
            <div className="px-4 flex justify-between items-center h-full">
              {orgName ? (
                <h1 className="">{orgName}</h1>
              ) : (
                <h1 className="">{t("welcome", { name: fullName })}</h1>
              )}
              <div className="flex items-center gap-2">
                {/* Hiding the Language switcher buttons */}
                {/* <Button type="primary" onClick={() => _handleLangChange("en")}>
                  English
                </Button>
                <Button type="primary" onClick={() => _handleLangChange("nl")}>
                  Dutch
                </Button> */}
                {/* NEED TO ADD A CONDITION TO MAKE SURE THAT A NORMAL USER CANNOT SEE THIS TOGGLE BUTTON */}
                {orgId &&
                  orgUserType === "admin" &&
                  (orgPortalView === "admin" ? (
                    <img
                      src={iconToggleUser_Navy}
                      alt=""
                      className="h-10 transition-opacity duration-150 hover:opacity-75 cursor-pointer"
                      onClick={() => _toggleView(orgPortalView)}
                    />
                  ) : (
                    <img
                      src={iconToggleAdmin_Navy}
                      alt=""
                      className="h-10 transition-opacity duration-150 hover:opacity-75 cursor-pointer"
                      onClick={() => _toggleView(orgPortalView)}
                    />
                  ))}

                {/* <img src={iconSettings_Navy} alt="" className="h-10 opacity-30" />
                <img src={iconHelp_Navy} alt="" className="h-10 opacity-30" />
                <img
                  src={iconLogout_Navy}
                  alt=""
                  className="h-10 transition-opacity duration-150 hover:opacity-75 cursor-pointer"
                  onClick={_logoutAll}
                /> */}
                <Link
                  to={orgId ? "/org/profile" : "/profile"}
                  className="whitespace-nowrap"
                >
                  <div className="ml-4 flex items-center flex-1">
                    <div className="relative overflow-hidden h-10 w-10 max-h-10 rounded-full bg-[#ffffff] flex justify-center items-center font-bold">
                      {currentLoginPic ? (
                        <img
                          src={currentLoginPic}
                          alt=""
                          className="w-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                        />
                      ) : (
                        fullName[0].toUpperCase()
                      )}
                    </div>
                    <span className="ml-2">{fullName}</span>
                  </div>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fullName: state.Auth.name,
    currentLoginPic: state.Auth.photo,
    appUserType: state.Auth.userType,
    orgPortalView: state.Organisation.org_portal_view,
    orgId: state.Organisation.org_login.id,
    orgLogo: state.Organisation.org_login.logo,
    orgName: state.Organisation.org_login.name,
    orgUserType: state.Organisation.org_login.orgUserType,
  };
};

const mapDispatchToProps = {
  logoutSystem,
  toggleOrganisationPortalView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
