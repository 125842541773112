import React from "react"

const Container = ({ type,...props}) => {

  let definedClasses;

  switch(type) {
    case 'centred':
      definedClasses = 'container mx-auto';
    break;

    default:
      definedClasses = '';
    break;
  }

  return <div className={`${definedClasses} px-8 ${props.classes}`}>{props.children}</div>
}

export default Container
