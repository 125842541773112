import React from "react";
import BackButton from "../atoms/BackButton";

const ModalPage = (props) => {
  const { imgSrc, withBackBtn, withBackBtnOnImg } = props;

  return imgSrc ? (
    <div className="fixed inset-0 pt-10 overflow-y-scroll bg-primary z-10">
      <div
        className={`mb-4 overflow-hidden rounded-md w-2/3 md:w-1/2 xl:w-1/3 max-w-[500px] mx-auto`}
      >
        <div
          className={`relative w-full bg-[#0D161D] flex justify-center pt-8`}
        >
          {withBackBtnOnImg && <BackButton fromImg={withBackBtnOnImg} />}
          <img src={imgSrc} className="w-1/2" alt="" />
        </div>
        <div className="p-6 pt-8 pb-12 space-y-4 bg-white">
          {props.children}
        </div>
      </div>
    </div>
  ) : (
    <div className="fixed inset-0 pt-10 overflow-y-scroll bg-primary z-10">
      <div
        className={`bg-white dark:bg-gray-700 dark:text-white p-6 pt-8 pb-12 mb-4 overflow-hidden rounded-md w-2/3 md:w-1/2 xl:w-1/3 max-w-[500px] mx-auto space-y-4`}
      >
        {withBackBtn && <BackButton />}
        {props.children}
      </div>
    </div>
  );
};

export default ModalPage;
