import React from "react"
import _ from "lodash"

const InputReadOnly = (props) => {
  return (
    <div className={`mb-10 ${!!props.css && props.css}`}>
      <label className={`form-label disabled text-oxfordBlue block mb-1`}>
        {props.label}
      </label>
      <div className="text-baliHai">{props.value}</div>
      {_.isString(props.error) && (
        <div className="form-error text-red-500 text-sm italic mt-2">
          {props.error}
        </div>
      )}
    </div>
  )
}

export default InputReadOnly
