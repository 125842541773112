import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
// Components
import { Button, NavLinks } from "../atoms";
import UploadVideo from "../../screens/Video/Modals/UploadVideo";
// Redux Actions
import { toggleOrganisationPortalView } from "../../redux/actions";
// Utilities
import { appNavLinks, orgNavLinks } from "../../utils/navigationLinks";
// import { StateView } from "../../utils/StateView";
import { _permissionsRequired } from "../../utils/helper";

const Sidebar = ({ orgId, userType, orgPortalView, orgUserType, ...props }) => {
  const { t } = useTranslation(["common"]);

  const { super_admin, user_admin } = appNavLinks;
  const { admin, user } = orgNavLinks;

  const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);

  useEffect(
    () => {
      /**
       * Sync the orgPortalView with orgUserType
       *  - Sometimes if Organisation admin in 1 org and Organisation user in another org,
       *    switching org may cause to show Sidebar for 'orgUserType: user'
       *    but the portal view flag is still 'org_portal_view: admin'
       */
      switch (orgUserType) {
        case "admin":
          if (orgPortalView === "user") {
            // console.log("Admin user");
            props.toggleOrganisationPortalView("user");
            break;
          } else {
            // console.log("Admin admin");
            props.toggleOrganisationPortalView("admin");
            break;
          }
        case "user": {
          // console.log("User");
          props.toggleOrganisationPortalView("user");
          break;
        }
        case "super_admin": {
          // console.log("Superadmin");
          props.toggleOrganisationPortalView("admin");
          break;
        }
        default: {
          props.toggleOrganisationPortalView("user");
          break;
        }
      }
    },
    // eslint-disable-next-line
    [orgUserType, orgPortalView]
  );

  // console.log("userType :", userType);
  const OrgPortalViewSidebar = useCallback(
    () => {
      // Switch Sidebar links based on Organisation User Type and Organisation Portal View
      switch (orgUserType) {
        case "admin":
          if (orgPortalView === "user") {
            return <NavLinks menu={user} />;
          } else {
            return <NavLinks type="admin" menu={admin} />;
          }
        case "user": {
          return <NavLinks menu={user} />;
        }
        case "super_admin": {
          return <NavLinks type="admin" menu={admin} />;
        }
        default: {
          return <NavLinks menu={user} />;
        }
      }
    },
    // eslint-disable-next-line
    [orgUserType, orgPortalView]
  );

  const adminCss = "bg-white w-[250px] h-full py-6 border-r-2 border-gray-200";
  const userCss = "bg-primary w-[250px] h-full text-white pt-6 pb-12";

  if (orgId) {
    return (
      <>
        <div className={`${orgPortalView === "admin" ? adminCss : userCss}`}>
          {/* <StateView
            values={showUploadVideoModal}
            title="showUploadVideoModal"
          /> */}
          <nav className="flex flex-col justify-between h-full max-h-[1000px]">
            <div>
              <OrgPortalViewSidebar />
            </div>
            {/* <StateView values={showUploadVideoModal} title="showUploadVideoModal" /> */}

            {/* HIDING UPLOAD VIDEO FUNCTION */}
            {orgPortalView === "user" && _permissionsRequired("UPLOAD") && (
              <Button
                type="primary"
                twWidth="w-3/4"
                twClasses="ml-4"
                onClick={() => setShowUploadVideoModal(true)}
              >
                {t("buttons.upload")}
              </Button>
            )}
          </nav>
        </div>
        {/* HIDING UPLOAD VIDEO FUNCTION */}
        {orgPortalView === "user" && (
          <UploadVideo
            uploadModalState={showUploadVideoModal}
            closeModal={() => setShowUploadVideoModal(false)}
          />
        )}
      </>
    );
  } else {
    return userType === "super_admin" ? (
      <div className="bg-white w-[250px] h-full pt-6 border-r-2 border-gray-200">
        <nav className="flex flex-col h-full">
          <NavLinks type="admin" menu={super_admin} />
        </nav>
      </div>
    ) : (
      <div className="bg-primary w-[250px] h-full text-white pt-6">
        <nav className="flex flex-col h-full">
          <NavLinks menu={user_admin} />
        </nav>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    orgId: state.Organisation.org_login.id,
    orgUserType: state.Organisation.org_login.orgUserType,
    orgPortalView: state.Organisation.org_portal_view,
    userType: state.Auth.userType,
  };
};

const mapDispatchToProps = {
  toggleOrganisationPortalView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
